import React from 'react'
import Button from '../../../../components/Button/Button'
import propTypes from 'prop-types'

const CustomTabs = ({ activeTab, onTabChange }) => {
    return (
        <div className="custom-tabs">
            <Button
                label="Anonymisierung"
                slug="Anonymisierung"
                isCTA={activeTab === 0}
                onClick={() => onTabChange(0)}
            />
            <Button
                label="De-anonymisierung"
                slug="De-anonymisierung"
                isCTA={activeTab === 1}
                onClick={() => onTabChange(1)}
            />
        </div>
    )
}

export default CustomTabs

CustomTabs.propTypes = {
    activeTab: propTypes.number,
    onTabChange: propTypes.func
}
