// AnonymizeUserInputText.js

function AnonymizeUserInputText (userInputText) {
    return new Promise(resolve => {
        setTimeout(() => {
            // Split the input text into words by spaces
            const words = userInputText.split(/\s+/)

            // Check if the text has fewer than 20 words
            if (words.length < 20) {
                resolve({ anonymizedWords: {}, replacedText: userInputText })
                return
            }

            // Function to get random unique indices for selecting words
            function getRandomIndices (count, max) {
                const indices = new Set()
                while (indices.size < count) {
                    const randomIndex = Math.floor(Math.random() * max)
                    indices.add(randomIndex)
                }
                return Array.from(indices)
            }

            // Get 10 unique random indices from the words array
            const randomIndices = getRandomIndices(10, words.length)

            // Create an object to store selected words with labels
            const anonymizedWords = {}
            randomIndices.forEach((index, i) => {
                anonymizedWords[`text_${i + 1}`] = words[index]
            })

            // Replace all occurrences of each selected word with the corresponding label
            let replacedText = userInputText
            for (const [label, word] of Object.entries(anonymizedWords)) {
                const regex = new RegExp(`\\b${word}\\b`, 'g')
                replacedText = replacedText.replace(regex, label)
            }

            resolve({ anonymizedWords, replacedText })
        }, 1000) // Wait for 1 second
    })
}

export default AnonymizeUserInputText
