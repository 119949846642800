import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { API_ENDPOINTS } from '../../../../config/apiConfig'
import PropTypes from 'prop-types'

const handleDownloadAnonymizedPDF = async (fileId, userId, userIdToken, ners) => {
    const apiUrl = API_ENDPOINTS.redactPDF
    const payload = {
        fileId,
        userId,
        ners
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userIdToken}`
            },
            body: JSON.stringify(payload)
        })

        if (response.status === 401) {
            throw new Error('UnauthorizedError')
        } else if (response.status === 500) {
            throw new Error('Invalid file format or empty file')
        } else if (response.status === 411) {
            throw new Error('Invalid language')
        } else if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}. Message: ${response.statusText || 'Unknown error'}`)
        }

        // Assuming the response is a file stream
        const blob = await response.blob() // Create a blob from the response
        const downloadUrl = window.URL.createObjectURL(blob) // Create a URL from the blob
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'anonymized_document.pdf') // Set the filename for download
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl) // Clean up the URL object

        return { success: true } // Indicate download was successful
    } catch (error) {
        console.error('An error occurred:', error)
        throw error
    }
}

const DownloadRedactedButton = ({ fileId, userId, userIdToken, ners, hasAnonymizationsData, setIsLoading, setAnonymizationErrorMessage }) => {
    const navigate = useNavigate()

    const handleDownloadClick = async () => {
        setIsLoading(true)
        try {
            await handleDownloadAnonymizedPDF(fileId, userId, userIdToken, ners)
            console.log('Download successful')
        } catch (error) {
            console.error('Download failed:', error.message)
            setAnonymizationErrorMessage(error.message)
            if (error.message === 'UnauthorizedError') {
                if (window.confirm('Your session has expired. Please sign out and sign in again.')) {
                    navigate('/signin')
                }
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            className="preview-btn action"
            style={{ marginTop: '0.5rem' }}
            onClick={handleDownloadClick}
            disabled={!hasAnonymizationsData}
        >
            Download anonymisierte PDF
        </Button>
    )
}

// Define PropTypes
DownloadRedactedButton.propTypes = {
    fileId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userIdToken: PropTypes.string.isRequired,
    ners: PropTypes.array.isRequired,
    hasAnonymizationsData: PropTypes.bool.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setAnonymizationErrorMessage: PropTypes.func.isRequired
}

export default DownloadRedactedButton
