import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import { createFolder } from '../../../../../../firebase/userOperations/createFolder'
import Button from '../../../../../../components/Button/Button'

function useCreateFolderLogic (userId, parentId, refreshFolderTree, setContextMenu) {
  const [folderNameDialogOpen, setFolderNameDialogOpen] = useState(false)
  const [folderName, setFolderName] = useState('')

  const handleCreateFolderClick = () => {
    setContextMenu(null)
    setFolderNameDialogOpen(true)
  }

  const handleCreateFolder = async () => {
    if (folderName) {
      await createFolder(userId, folderName, parentId)
      refreshFolderTree()
    }
    setFolderNameDialogOpen(false)
    setFolderName('')
  }

  const CreateFolderDialog = (
        <Dialog
            open={folderNameDialogOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setFolderNameDialogOpen(false)
                }
            }}
            >
            <DialogTitle>Neuer Ordner</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Ordnername"
                    type="text"
                    fullWidth
                    value={folderName}
                    onChange={e => setFolderName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setFolderNameDialogOpen(false)
                        setFolderName('')
                    }}
                    label="Abbrechen"
                    isCTA/>
                <Button
                    onClick={handleCreateFolder}
                    disabled={!folderName.trim()}
                    isCTA={folderName.trim()}
                    label="Erstellen"/>
            </DialogActions>
        </Dialog>
  )

  return { handleCreateFolderClick, CreateFolderDialog }
}

export default useCreateFolderLogic
