import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

function Pagination ({ resultsPerPage, totalResults, paginate }) {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalResults / resultsPerPage)
  const totalBoxes = 9 // Including the current page, before, and after pages
  const halfWay = Math.floor(totalBoxes / 2)

  useEffect(() => {
    paginate(currentPage)
  }, [currentPage, paginate])

  const handleClick = (event) => {
    event.preventDefault()
    const target = event.target
    if (target.nodeName.toLowerCase() === 'a') {
      const newPage = Number(target.getAttribute('data-page')) || currentPage
      setCurrentPage(newPage)
    }
  }

  const paginationItems = () => {
    let startPage = Math.max(1, currentPage - halfWay)
    const endPage = Math.min(totalPages, startPage + totalBoxes - 1)

    if (currentPage > totalPages - halfWay) {
      startPage = Math.max(1, totalPages - totalBoxes + 1)
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  }

  return (
      <nav>
        <ul className='pagination' onClick={handleClick}>
          <a href="#" data-page={Math.max(1, currentPage - 1)} aria-label="Previous" className={currentPage === 1 ? 'disabled' : ''}>&laquo;</a>
          {paginationItems().map(pageNumber => (
              <a href="#" key={pageNumber} data-page={pageNumber} className={pageNumber === currentPage ? 'active' : ''}>
                {pageNumber}
              </a>
          ))}
          <a href="#" data-page={Math.min(totalPages, currentPage + 1)} aria-label="Next" className={currentPage === totalPages ? 'disabled' : ''}>&raquo;</a>
        </ul>
      </nav>
  )
}

Pagination.propTypes = {
  resultsPerPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired
}

export default Pagination
