// apiConfig.js
export const API_ENDPOINTS = {
  parsePDF: 'https://parse-pdf-service-mwfawaf2kq-ey.a.run.app/parse-pdf',
  anonymizePDF: 'https://presidio-anonymizer-service-mwfawaf2kq-ey.a.run.app',
  verdictAnonymizer: 'https://anonymizer-verdict-ch-mwfawaf2kq-ey.a.run.app/anonymize',
  redactPDF: 'https://anonymizer-verdict-ch-mwfawaf2kq-ey.a.run.app/redact',
  manualAddition: 'https://anonymizer-verdict-ch-mwfawaf2kq-ey.a.run.app/manual_addition',
  // verdictAnonymizer: 'http://0.0.0.0:8081/anonymize',
  // redactPDF: 'http://0.0.0.0:8081/redact',
  // manualAddition: 'http://0.0.0.0:8081/manual_addition',
  querySQL: 'http://localhost:5000/q',
  cluster: 'http://localhost:5000/cluster',
  chat: 'http://localhost:5000/chat'
}
