import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'

const registerUser = async (email, password) => {
    const auth = getAuth()
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        // User successfully created
        const user = userCredential.user
        // Send a verification email to the user
        await sendEmailVerification(user)
        return user
    } catch (error) {
        console.error('Error c new user:', error)
        // Check if the email already exists
        if (error.code === 'auth/email-already-in-use') {
            throw new Error('This email is already in use.')
        } else {
            // Other errors can be handled here
            throw error
        }
    }
}

export default registerUser
