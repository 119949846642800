import React from 'react'
import styles from './Partners.module.scss'
import knowledgeToolsLogo from '../../assets/images/partners/knowledge-tools.png'
import LTC from '../../assets/images/partners/LTC-Logo-Full-Dark.png'
import ddductrLogo from '../../assets/images/partners/ddductr.svg'
import ndm from '../../assets/images/partners/NdM.png'
import euaid from '../../assets/images/partners/euai.png'

export default function Partners () {
    return (
        <section className={styles.partners} id='partnerinnen' aria-label="Unsere Partner">
            <h2>Unsere Kunden und Partner</h2>
            <ul>
                <li>
                    <figure>
                        <img src={LTC} alt='Legal Tech Colab Logo' title='Legal Tech Colab' />
                    </figure>
                </li>
                <li>
                    <figure>
                        <img src={euaid} alt='European AI Alliance Logo' title='European AI Alliance' />
                    </figure>
                </li>
                <li>
                    <figure>
                        <img src={knowledgeToolsLogo} alt='Knowledge Tools Logo' title='Knowledge Tools' />
                    </figure>
                </li>
                <li>
                    <figure>
                        <img src={ndm} alt='Neue Deutsche Medienmacher Logo' title='Neue Deutsche Medienmacher' />
                    </figure>
                </li>
                <li>
                    <figure>
                        <img src={ddductrLogo} alt='ddductr Logo' title='ddductr' />
                    </figure>
                </li>
            </ul>
        </section>
    )
}
