import React, { useState, useEffect, useRef } from 'react'
import { Dialog, DialogContent, DialogTitle, TextField, IconButton, List, ListItem, ListItemText } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import fetchChat from '../FetchRequests/fetchChat'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100vw',
    height: '80vh'
  }
  // ... other styles
}))
function ChatBot ({ open, documents, onClose, query }) {
  const classes = useStyles()
  const userIdToken = useSelector((state) => state.auth.userIdToken)
  const [message, setMessage] = useState('')
  const [history, setHistory] = useState([])
  const [isSending, setIsSending] = useState(false)
  const endOfMessagesRef = useRef(null)
  const [triggerFetch, setTriggerFetch] = useState(false)
  console.log('documents', documents)

    useEffect(() => {
        // This effect should only run once when the component mounts and query is available.
        // Also, ensure it runs only when the chat is opened and history is empty to avoid duplication.
        if (open && history.length === 0 && query) {
            // Simulate adding a user message with the query.
            const simulateUserMessage = async () => {
                setHistory([{ type: 'user', message: query }])
                // After setting the user message, fetch the bot's response.
                try {
                    setIsSending(true)
                    const botResponse = await fetchChat([{ type: 'user', message: query }], documents, userIdToken)
                    setHistory((prevHistory) => [
                        ...prevHistory,
                        { type: 'bot', message: botResponse.message }
                    ])
                    setIsSending(false)
                } catch (error) {
                    console.error('Error fetching initial chat response:', error)
                    setIsSending(false)
                }
            }
            simulateUserMessage()
        }
        // Remove triggerFetch from the dependency array to prevent re-running due to its change.
    }, [query, open]) // Ensure this runs only when the component mounts and when query or open changes.

    // useEffect to call fetchChat when history is updated with user message
    useEffect(() => {
        if (triggerFetch) {
            const fetchChatData = async () => {
                setIsSending(true) // Disable sending new messages
                try {
                    const botResponse = await fetchChat(history, documents, userIdToken)
                    setHistory((prevHistory) => [...prevHistory, { type: 'bot', message: botResponse.message }])
                    setMessage(botResponse.message)
                } catch (error) {
                    console.error('Error sending message:', error)
                } finally {
                    setIsSending(false) // Ensure we re-enable sending whether or not there was an error
                }
            }

            fetchChatData()
        }
        // Reset triggerFetch to false after fetchChat is called
        setTriggerFetch(false)
    }, [triggerFetch]) // Dependency array ensures this runs only when triggerFetch changes

    const sendMessage = () => {
        if (!isSending) {
            // First, update history with user's message
            setHistory((prevHistory) => [...prevHistory, { type: 'user', message }])
            // Then, set triggerFetch to true to indicate that fetchChat should be called next
            setTriggerFetch(true)
        }
    }
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [history])
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }
  const resetChat = () => {
    setHistory([]) // Clears the chat history
    setIsSending(false) // Ensures that sending is enabled
  }
  return (
      <Dialog
          open={open}
          onClose={onClose}
          classes={{ paper: classes.dialogPaper }}
      >
            <DialogTitle className="chatbot-title">Chat with {documents.length} selected document{documents.length > 1 ? 's' : ''}</DialogTitle>
            <DialogContent dividers className="chatbot-content">
                <List className="chat-history">
                    {history.map((item, index) => (
                        <ListItem
                            key={index}
                            className="chat-message"
                        >
                            <ListItemText
                                primary={item.message}
                                secondary={item.type === 'user' ? 'You' : 'Bot'}
                                className={item.type === 'user' ? 'chat-message-user' : 'chat-message-bot'}
                            />
                        </ListItem>
                    ))}
                    <div ref={endOfMessagesRef} />
                </List>
                <div className="chat-input-container">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Your Message"
                        multiline
                        minRows={2} // Replace with your desired minimum row count
                        maxRows={6} // Replace with your desired maximum row count before scrolling
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="chat-input-field"
                    />

                    <Tooltip title="Send Message">
                        <IconButton color="primary" onClick={sendMessage} className="chat-send-button" disabled={isSending} >
                        <SendIcon />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear Caht History">
                        <IconButton color="secondary" onClick={resetChat} className="chat-reset-button">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogContent>
        </Dialog>
  )
}

ChatBot.propTypes = {
  open: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
}

export default ChatBot
