import { getFirestore, collection, doc, setDoc, Timestamp } from 'firebase/firestore'

const db = getFirestore()

export const createFolder = async (userId, name, parentId = null) => {
  try {
    const foldersRef = collection(db, 'folders')
    const folderRef = doc(foldersRef)

    const folderData = {
      id: folderRef.id,
      name,
      user_id: userId,
      created_at: Timestamp.now(),
      parent_id: parentId,
      children_ids: []
    }

    await setDoc(folderRef, folderData)

    // Return the folder data
    return folderData
  } catch (error) {
    console.error('Error creating folder: ', error)
    return null
  }
}
