import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Products.module.scss'

export default function Products ({ headline, items, id }) {
  const [currentItem, setCurrentItem] = useState(0)

  const handlePaginationClick = (index) => {
    setCurrentItem(index)
  }

  const renderPagination = () => {
    return items?.map((item, index) => (
        <span
            key={index}
            className={`${styles.dot} ${index === currentItem ? styles.active : ''}`}
            onClick={() => handlePaginationClick(index)}
        />
    ))
  }

  return (
      <section className={styles.products} id={id} >
        <h2>{headline}</h2>
        <div className={styles.item} >
          <div className={styles.text}>
            <h3>{items[currentItem]?.title}</h3>
            <ul>
              {items[currentItem]?.facts?.map((fact, index) => (
                  <li key={index}>{fact}</li>
              ))}
            </ul>
            <div className={`${styles.pagination}`}>
              {renderPagination()}
            </div>
          </div>
          <img src={items[currentItem]?.media} alt={items[currentItem]?.description}/>
        </div>
      </section>
  )
}

Products.propTypes = {
  headline: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  id: PropTypes.string,
  facts: PropTypes.array
}
