import React from 'react'
import PropTypes from 'prop-types'
import { LinearProgress, withStyles } from '@material-ui/core'

// Function to determine the color based on progress
const getColorBasedOnProgress = (progress) => {
  // Define the color stops for the gradient
  const colors = {
    0: 'hsla(206, 100%, 50%, 1)', // $blue
    10: 'hsla(206, 90%, 78%, 1)', // $blue-pale
    20: 'hsla(90, 62%, 80%, 1)', // $green-pale
    30: 'hsla(52, 100%, 54%, 1)', // $yellow
    40: 'hsla(52, 94%, 80%, 1)', // $yellow-pale
    50: 'hsla(20, 94%, 80%, 1)', // $orange-pale
    60: 'hsla(20, 100%, 75%, 1)', // Adjusted for $orange-half-pale to demonstrate progression
    70: 'hsla(20, 100%, 50%, 1)', // $orange
    80: 'hsla(20, 100%, 50%, 1)', // $cadet as a contrasting element or consider removing if it disrupts the gradient flow
    90: '#171738',
    100: '#171738'
  }
  // Find the two closest stops
  const lowerBound = Object.keys(colors).reverse().find(key => progress >= key)
  const upperBound = Object.keys(colors).find(key => progress <= key)
  // If progress is exactly at a color stop
  if (lowerBound === upperBound) {
    return colors[lowerBound]
  }
  // Calculate the gradient ratio
  const range = upperBound - lowerBound
  const rangeProgress = (progress - lowerBound) / range
  // Interpolate between the two colors
  const lowerColor = colors[lowerBound]
  const upperColor = colors[upperBound]
  return `linear-gradient(to right, ${lowerColor}, ${upperColor} ${rangeProgress * 100}%)`
}

// Custom styling for the LinearProgress component
const ColoredLinearProgress = withStyles({
  root: {
    height: 4
  },
  bar: {
    background: props => getColorBasedOnProgress(props.progress) // Use the gradient function
  }
})(LinearProgress)

function QueryProgress ({ searchTerm }) {
  const calculateProgressPercentage = () => {
    const queries = searchTerm.split(';').slice(0, 4) // Limit to three queries
    let wordCount = 0

    queries.forEach((query) => {
      const words = query.trim().split(/\s+/).filter(word => {
        // Check if the word has more than three characters and is not a number
        return word.length > 3 && isNaN(word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''))
      })
      wordCount += Math.min(words.length, 4) // Cap word count per query at 4
    })

    return Math.min(wordCount * 8, 100) // Each word adds 12.5% progress, max 100%
  }

  const progressPercentage = calculateProgressPercentage()

  return (
      <div style={{ width: '100%' }}>
        <ColoredLinearProgress
            variant="determinate"
            value={progressPercentage}
            progress={progressPercentage} // Pass progress as a prop
        />
      </div>
  )
}

QueryProgress.propTypes = {
  searchTerm: PropTypes.string.isRequired
}

export default QueryProgress
