import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './PseudoLab.scss'
import AnonymizeTab from './components/AnonymizeTab'
import CustomTabs from './components/CustomTabs'
import DeAnonymizeTab from './components/DeAnonymizeTab'

const PseudoLab = () => {
    const [activeTab, setActiveTab] = useState(0)
    const [containerHeight, setContainerHeight] = useState('100vh')

    const headerHeight = useSelector((state) => state.layout.headerHeight)
    const footerHeight = useSelector((state) => state.layout.footerHeight)

    const handleTabChange = (newValue) => {
        setActiveTab(newValue)
    }

    useEffect(() => {
        const adjustHeight = () => {
            const newHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`
            setContainerHeight(newHeight)
        }

        adjustHeight()
        window.addEventListener('resize', adjustHeight)
        return () => {
            window.removeEventListener('resize', adjustHeight)
        }
    }, [headerHeight, footerHeight])

    return (
        <Box className="pseudo-lab-background" sx={{ height: `calc(100vh - ${footerHeight}px)` }}>
            <Box className="pseudo-lab" sx={{ mt: `${headerHeight}px`, height: containerHeight }}>
                <CustomTabs activeTab={activeTab} onTabChange={handleTabChange} />
                <Box className="tab-content">
                    {activeTab === 0 && <AnonymizeTab />}
                    {activeTab === 1 && <DeAnonymizeTab />}
                </Box>
            </Box>
        </Box>
    )
}

export default PseudoLab
