import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './i18n'

const rootElement = document.getElementById('root')
if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<HelmetProvider>
				<Router>
					<Suspense fallback={<div>Loading…</div>}>
						<App />
					</Suspense>
				</Router>
			</HelmetProvider>
		</React.StrictMode>
	)
}
