import React from 'react'
import iconCourt from '../../assets/images/icons/court_white.svg'
import iconJustice from '../../assets/images/icons/justice_white.svg'
import iconFirms from '../../assets/images/icons/firms_white.svg'
import styles from './Clients.module.scss'

export default function Clients () {
  return (
    <section className={styles.clients} id='kundinnen'>
      <h2>Eine KI für alle Fälle.</h2>
      <p>Mit dem Sprachmodell VerdictLLM von Elephant Labs sparen Sie wertvolle Zeit bei der Verarbeitung von Rechtsdokumenten. Eine juristische KI, welche die Arbeit von Jurist:innen, Gerichten und Behörden vereinfacht und vielseitig einsetzbar ist.</p>
      <ul>
        <li>
          <img src={iconCourt} alt='Gerichte' />
          <div>
            <h4>Gerichte</h4>
            <p>Sichere und zuverlässige Anonymisierung von Gerichtsurteilen in Sekunden</p>
          </div>
        </li>
        <li>
          <img src={iconJustice} alt='Jurist:innen' />
          <div>
            <h4>Jurist:innen</h4>
            <p>Leistungsstarke Suchmaschine für Dokumente anhand intelligenten Filtern</p>
          </div>
        </li>
        <li>
          <img src={iconFirms} alt='Behörden & Kanzleien' />
          <div>
            <h4>Behörden & Kanzleien</h4>
            <p>Individuelle Anonymisierung von sensiblen Rechtsdokumenten</p>
          </div>
        </li>
      </ul>
    </section>
  )
}
