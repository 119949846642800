import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../../../firebase/firebase'
import { encryptData } from './Utils'
import { getFirestore, collection, doc, setDoc, Timestamp, getDocs, query, where } from 'firebase/firestore'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '../../../../../../components/Button/Button'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    position: 'relative'
  },
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  }
}))

function useUploadFileLogic (userId, parentId, refreshFolderTree, setContextMenu) {
  const classes = useStyles()
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const [file, setFile] = useState(null)
  const db = getFirestore() // Initialize Firestore

  const handleUploadFileClick = () => {
    setContextMenu(null)
    setUploadDialogOpen(true)
  }

  const checkFileExists = async (fileName, userId, parentId) => {
    const filesRef = collection(db, 'files')
    const querySnapshot = await getDocs(query(filesRef, where('name', '==', fileName), where('user_id', '==', userId), where('parent_id', '==', parentId)))
    return !querySnapshot.empty
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleUploadFile = async () => {
    if (!file) {
      return
    }
    setUploading(true)
    if (file) {
      const fileExists = await checkFileExists(file.name, userId, parentId)
      if (fileExists) {
        setUploadDialogOpen(false)
        setUploading(false)
        alert('Eine Datei mit demselben Namen existiert bereits in diesem Ordner.')
        return
      }
      // Encrypt the file data
      const fileReader = new FileReader()
      fileReader.onload = async (e) => {
        const arrayBuffer = e.target.result
        const { encryptedData, rawKey, iv } = await encryptData(arrayBuffer)

        // Create a reference to the files collection
        const filesRef = collection(db, 'files')
        const fileDocumentRef = doc(filesRef)

        // Create a reference to the location in Google Storage
        const fileRef = ref(storage, `userFiles/${userId}/${parentId}/${file.name}`)

        // Upload the encrypted data to Google Storage
        const uploadTask = uploadBytesResumable(fileRef, new Uint8Array(encryptedData))
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadProgress(progress)
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(error)
            setUploadDialogOpen(false)
            setUploading(false) // End the uploading process
            setUploadProgress(0) // Reset progress
            refreshFolderTree() // Refresh the tree
          },
          async () => {
            // Create a file document
            const fileDocument = {
              id: fileDocumentRef.id,
              name: file.name,
              user_id: userId,
              parent_id: parentId,
              cryptoKey: Array.from(rawKey), // Convert to regular array
              iv: Array.from(iv), // Convert to regular array
              created_at: Timestamp.now()
            }

            // Save the file document
            await setDoc(fileDocumentRef, fileDocument)

            setUploadDialogOpen(false)
            setUploading(false) // End the uploading process
            setUploadProgress(0) // Reset progress
            setFile(null) // Reset the file
            refreshFolderTree() // Refresh the tree
          }
        )
      }
      fileReader.readAsArrayBuffer(file)
    }
    // setUploadDialogOpen(false)
  }

  const UploadFileDialog = (
      <Dialog
          open={uploadDialogOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              setUploadDialogOpen(false)
            }
          }}
          classes={{ paper: classes.dialogPaper }}
      >
        {uploading && <LinearProgress className={classes.linearProgress} variant="determinate" value={uploadProgress} />}
        <DialogTitle>Datei hochladen</DialogTitle>
        <DialogContent>
          <input type="file" onChange={handleFileChange} accept=".pdf" />
          {file && file.type !== 'application/pdf' && (
              <p style={{ color: 'red' }}>Unterstützt derzeit nur PDF Dateien.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
              onClick={() => {
                  setUploadDialogOpen(false)
                  setFile(null)
              }}
              disabled={uploading}
              isCTA={!uploading}
              label="Abbrechen"/>
          <Button
              onClick={handleUploadFile}
              disabled={!file || file.type !== 'application/pdf' || uploading}
              isCTA={file && file.type === 'application/pdf' && !uploading}
              label="Hochladen"/>
        </DialogActions>
      </Dialog>
  )

  return { handleUploadFileClick, UploadFileDialog }
}

export default useUploadFileLogic
