import React from 'react'
import { HashLink } from 'react-router-hash-link'
import styles from './Hero.module.scss'
import WordList from '../WordList/WordList'

export default function Hero () {
  const wordList = [
    'Anonymisierung',
    'Urteilssuche',
    'Recht & KI'
  ]

  return (
    <section className={styles.hero} id='top'>
      <h1><WordList words={wordList} /> auf einfach.</h1>
          <h3>
            Anonymisieren und finden Sie Rechtsdokumente in Sekunden mit <span className={styles.italic}>VerdictLLM</span>
          </h3>
        <HashLink smooth to={'#partnerinnen'}>
        <svg aria-label='Zum nächsten Abschnitt' viewBox='0 0 18 10' xmlns='http://www.w3.org/2000/svg'>
          <path d='M16.0711 1.07108L9 8.14215L1.92893 1.07108' />
        </svg>
      </HashLink>
    </section>
  )
}
