import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Accordion, AccordionDetails, AccordionSummary, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const menuItems = [
    {
        key: 'Server location',
        subMenu: [
            { key: 'Deutschland' },
            { key: 'Schweiz' }
        ]
    },
    {
        key: 'Grad der Anonymisierung',
        subMenu: [
            { key: 'Normal' },
            { key: 'Stark' }
        ]
    }
]

const MenuItemWithSubMenuAccordion = ({ data, selected, onSelect }) => {
    const { key, subMenu } = data || {}

    return (
        <MenuItem id={key}>
            {subMenu.length === 0 ? (
                key
            ) : (
                <Accordion
                    elevation={0}
                    disableGutters
                    sx={{
                        backgroundColor: 'transparent'
                    }}
                >
                    <AccordionSummary
                        sx={{
                            padding: 0,
                            minHeight: 0
                        }}
                    >
                        <span>{key}</span>
                        <ArrowRightIcon style={{ marginLeft: 'auto' }} />
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            padding: 0,
                            margin: 0,
                            marginTop: '.5rem'
                        }}
                    >
                        {subMenu.map((subMenuItem, i) => (
                            <MenuItem
                                key={i}
                                selected={selected === subMenuItem.key}
                                onClick={() => onSelect(key, subMenuItem.key)}
                            >
                                {subMenuItem.key}
                            </MenuItem>
                        ))}
                    </AccordionDetails>
                </Accordion>
            )}
        </MenuItem>
    )
}

MenuItemWithSubMenuAccordion.propTypes = {
    data: PropTypes.shape({
        key: PropTypes.string.isRequired,
        subMenu: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired
        })).isRequired
    }).isRequired,
    selected: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
}

const AnonymizationTabSettingsMenu = ({
    anchorEl,
    handleMenuClose,
    serverLocation,
    setServerLocation,
    anonymizationDegree,
    setAnonymizationDegree
  }) => {
    const handleSelect = (category, value) => {
      if (category === 'Server location') {
        setServerLocation(value)
      } else if (category === 'Grad der Anonymisierung') {
        setAnonymizationDegree(value)
      }
      handleMenuClose()
    }
    return (
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'settings-button'
        }}
        PaperProps={{
          className: 'settings-menu-paper' // Add this line
        }}
      >
        {menuItems.map((data, i) => (
          <MenuItemWithSubMenuAccordion
            key={i}
            data={data}
            selected={data.key === 'Server location' ? serverLocation : anonymizationDegree}
            onSelect={handleSelect}
          />
        ))}
      </Menu>
    )
  }
AnonymizationTabSettingsMenu.propTypes = {
    anchorEl: PropTypes.object,
    handleMenuClose: PropTypes.func.isRequired,
    serverLocation: PropTypes.string.isRequired,
    setServerLocation: PropTypes.func.isRequired,
    anonymizationDegree: PropTypes.string.isRequired,
    setAnonymizationDegree: PropTypes.func.isRequired
}

export default AnonymizationTabSettingsMenu
