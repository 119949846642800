export default {
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'transparent',
    border: '1px solid gray',
    padding: '5px',
    cursor: 'pointer',
    width: 'max-content'
  },
  menuItem: {
    color: 'gray',
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'left',
    fontFamily: 'Montserrat'
  },
  colors: {
    primary: '#ffffff', // Google blue
    secondary: '#34A853', // Google green
    danger: '#EA4335', // Google red
    text: '#3C4043', // Google text color
    background: '#F5F5F5' // Google background color
  },
  typography: {
    title: {
      fontWeight: 'bold'
    },
    normal: {
      fontSize: '16px'
    },
    small: {
      fontSize: '12px'
    }
  },
  spacing: {
    normal: '16px',
    large: '24px',
    small: '8px',
    tiny: '4px'
  }
}
