import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MobileFilterButton from './MobileFilterButton' // Import the mobile filter button component
import QueryProgress from './QueryProgress' // Import the query progress component
import { clearResults } from '../../../../slices/selectedChatSearchDocumentsSlice'
import { useDispatch } from 'react-redux'

function SearchBar ({ onSearch, searchActive, isMobile, isFilterVisible, handleFilterToggle, filters, setAppliedFilters, setSelectedFilters }) {
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch()

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchClick = () => {
    onSearch(searchTerm) // Pass searchTerm to the onSearch function
    dispatch(clearResults()) // Clear the selected results
      // const [selectedFilters, setSelectedFilters] = useState(['topic_title', 'court_meta_de', 'year', 'language']
      //     .reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
      // const [appliedFilters, setAppliedFilters] = useState({ ...selectedFilters })
      // reset the applied filters
    const selectedFilters = ['topic_title', 'court_meta_de', 'year', 'language'].reduce((acc, key) => ({ ...acc, [key]: [] }), {})
    setAppliedFilters({ ...selectedFilters })
    setSelectedFilters({ ...selectedFilters })
  }

  return (
        <div className={`search-bar-wrapper ${searchActive ? 'search-active' : ''}`}>
            <div className="search-input-container">
                <input
                    type="text"
                    placeholder="Fragen oder beschreiben Sie Ihr Anliegen. Für Multiquery-Suche verwenden Sie Semikolons (;)."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <QueryProgress searchTerm={searchTerm}/>
                <div className={`search-info ${searchTerm ? 'visible' : ''}`}>
                    <p>
                        Fragen oder beschreiben Sie Ihr Anliegen. Für Multiquery-Suche verwenden Sie Semikolons
                        (;). <span className="italic-text">Anfragen: {searchTerm.split(';').length}</span>
                    </p>
                </div>
            </div>
            <div className="buttons-container">
                {isMobile && searchActive && (
                    <MobileFilterButton
                        isFilterVisible={isFilterVisible}
                        handleFilterToggle={handleFilterToggle}
                        filters={filters}
                    />
                )}
                <button
                    onClick={handleSearchClick}
                    disabled={!searchTerm.trim()} // Disable if searchTerm is empty or whitespace
                    className={`search-button ${searchTerm.trim() ? 'action' : ''}`}
                >
                    Suchen
                </button>
            </div>
        </div>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isFilterVisible: PropTypes.bool.isRequired,
  handleFilterToggle: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  setAppliedFilters: PropTypes.func,
  setSelectedFilters: PropTypes.func
}

export default SearchBar
