import React from 'react'
import { Popover, MenuItem, List } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../../../../styles/styles'
import { useDispatch, useSelector } from 'react-redux'
import { setPDFViewerActive, setPDFViewerBlobUrl } from '../../../../../slices/collaborativeDocumentAnonymizationStateSlice'
import useCreateFolderLogic from './Logics/CreateFolderLogic'
import useUploadFileLogic from './Logics/UploadFileLogic'
import { downloadAndDecryptFile } from './Logics/DownloadFileLogic'
import useDeleteFileLogic from './Logics/DeleteFileLogic'
import useDeleteFolderLogic from './Logics/DeleteFolderLogic'
import { useLocation, useNavigate } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    border-radius: 4px;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
    padding: ${styles.spacing.tiny}; // Decreased padding
  }
`

function TreeItemsContextMenu ({ position, setContextMenu, id, type, refreshFolderTree, onFileDelete }) {
  const userId = useSelector((state) => state.auth.userId)
  const blobUrl = useSelector((state) => state.selectedFileSlice[id]?.blobUrl)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const handleContextMenuClose = () => {
    setContextMenu(null)
  }

  const { handleCreateFolderClick, CreateFolderDialog } = useCreateFolderLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleUploadFileClick, UploadFileDialog } = useUploadFileLogic(userId, id, refreshFolderTree, setContextMenu)
  const { handleDeleteFileClick, DeleteFileDialog } = useDeleteFileLogic(userId, id, refreshFolderTree, setContextMenu, onFileDelete)
  const { handleDeleteFolderClick, DeleteFolderDialog } = useDeleteFolderLogic(userId, id, refreshFolderTree, setContextMenu)
  const handleBatchAnonymizationClick = async () => {
      const confirm = window.confirm('Please contact the sales team to enable this feature.')
      if (confirm) {
          // log current lcoation
          navigate('/#kontakt', { state: { from: location.pathname } })
      }
  }
  const handleDownloadFileClick = () => {
    setContextMenu(null)
    downloadAndDecryptFile(userId, id, false)
  }

  const handlePreviewFileClick = () => {
    setContextMenu(null)
    dispatch(setPDFViewerBlobUrl(blobUrl))
    dispatch(setPDFViewerActive(true))
  }

  return (
        <div>
            <StyledPopover
                id="simple-menu"
                anchorReference="anchorPosition"
                anchorPosition={position}
                keepMounted
                open={Boolean(position)}
                onClose={handleContextMenuClose}
                PaperProps={{
                  style: {
                    borderRadius: 4,
                    boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
                    padding: styles.spacing.tiny
                  }
                }}
            >
                <List style={{ textAlign: 'center' }}>
                    {type === 'folder' && (
                        <>
                            <MenuItem onClick={handleBatchAnonymizationClick}>Batch Anonymisierung</MenuItem>
                            {/* add a gray line as a separator */}
                            <Divider style={{ margin: '5px 0' }} />
                            <MenuItem onClick={handleUploadFileClick}>Datei hochladen</MenuItem>
                            <MenuItem onClick={handleCreateFolderClick}>Ordner erstellen</MenuItem>
                            <MenuItem onClick={handleDeleteFolderClick}>Ordner löschen</MenuItem>
                        </>
                    )}
                    {type === 'file' && (
                        <>
                            <MenuItem onClick={handlePreviewFileClick}>Vorschau</MenuItem>
                            <MenuItem onClick={handleDownloadFileClick}>Herunterladen</MenuItem>
                            <MenuItem onClick={handleDeleteFileClick}>Löschen</MenuItem>
                        </>
                    )}
                </List>
            </StyledPopover>
            {CreateFolderDialog}
            {DeleteFileDialog}
            {UploadFileDialog}
            {DeleteFolderDialog}
        </div>
  )
}

TreeItemsContextMenu.propTypes = {
  position: PropTypes.object,
  setContextMenu: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  refreshFolderTree: PropTypes.func,
  onFileDelete: PropTypes.func.isRequired
}

export default TreeItemsContextMenu
