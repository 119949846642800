import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

const loginUser = async (email, password) => {
    const auth = getAuth()
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        // User successfully logged in
        return userCredential.user
    } catch (error) {
        console.error('Error logging in user:', error)
        throw error
    }
}

export default loginUser
