import React, { useEffect, useState } from 'react'
import FolderTreeData from './PDFViewer/FolderTreeData'
import { useSelector } from 'react-redux'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { CircularProgress } from '@material-ui/core'
import FileInfoSection from './FileInfoSection'
import PDFViewer from './PDFViewer/PDFViewer'
import useHandleItemClick from './Logics/useHandleItemClick'
import useFetchFolderTreeData from '../../../hooks/useFetchFolderTreeData'
import { useNavigate } from 'react-router-dom'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDF_EXTENSION = '.pdf'

function AnonymizationPWA () {
    const navigate = useNavigate()
    const userId = useSelector((state) => state.auth.userId)
    const PDFViewerActive = useSelector((state) => state.collaborativeDocumentAnonymizationStateSlice.pdfViewer.isActive)
    const headerHeight = useSelector((state) => state.layout.headerHeight)
    const footerHeight = useSelector((state) => state.layout.footerHeight)
    const pwaContainerHeight = `calc(100vh - 1*${headerHeight}px - 1*${footerHeight}px)`
    const [currentPage, setCurrentPage] = useState(1)
    const [numPages, setNumPages] = useState(null)
    const [selectedFileInfo, setSelectedFileInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false) // Loading state

    const handleItemClick = useHandleItemClick(setSelectedFileInfo, setIsLoading, userId, setCurrentPage)

    const { folderTreeData, loading, authenticated, handleSignIn, refreshFolderTreeData } = useFetchFolderTreeData()

    const handleFileDeletion = () => {
        setSelectedFileInfo(null)
    }
    const updateSelectedFileInfo = (updates) => {
        setSelectedFileInfo((prevInfo) => ({ ...prevInfo, ...updates }))
    }

    useEffect(() => {
        const resizeHandler = () => {
            const pwaContainer = document.querySelector('.pwa-container')
            const treeFolderContainer = document.querySelector('.tree-folder-container')
            const fileInfoSection = document.querySelector('.file-info-section')

            if (pwaContainer && treeFolderContainer) {
                const heightForChild = pwaContainer.clientHeight
                treeFolderContainer.style.height = `calc(${heightForChild}px - 10px)`
                fileInfoSection.style.height = `calc(${heightForChild}px - 10px)`
            }
        }
        resizeHandler()
        window.addEventListener('resize', resizeHandler)
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    useEffect(() => {
        let timer
        if (userId === null || userId === undefined) {
            timer = setTimeout(() => {
                const confirm = window.confirm('Sie sind nicht angemeldet. Möchten Sie sich anmelden?')
                if (confirm) {
                    navigate('/signin')
                }
            }, 500)
        }
        return () => clearTimeout(timer)
    }, [userId, navigate])

    return (
        <div className="pwa-container" style={{ height: pwaContainerHeight, marginTop: headerHeight }}>
            {(!userId || userId === undefined) && (
                <div className="login-overlay">
                    Bitte melden Sie sich an, um fortzufahren.
                </div>
            )}
            {isLoading && (
                <div className="loading-overlay">
                    <CircularProgress />
                </div>
            )}
            <div className="folder-tree-section">
                <FolderTreeData
                    onItemClick={handleItemClick}
                    onFileDelete={handleFileDeletion}
                    folderTreeData={folderTreeData}
                    loading={loading}
                    authenticated={authenticated}
                    handleSignIn={handleSignIn}
                    refreshFolderTreeData={refreshFolderTreeData}
                />
            </div>

            <FileInfoSection
                selectedFileInfo={selectedFileInfo}
                updateSelectedFileInfo={updateSelectedFileInfo}
                refreshFolderTreeData={refreshFolderTreeData}
            />

            {PDFViewerActive && (
                <div className="pdf-viewer-section">
                    <PDFViewer
                        fileID={selectedFileInfo?.id}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        numPages={numPages}
                        setNumPages={setNumPages}
                    />
                </div>
            )}
        </div>
    )
}

export default AnonymizationPWA
