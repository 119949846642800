import React, { useRef, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, TextField, Typography, Button as MuiButton, IconButton } from '@material-ui/core'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '50%',
        maxWidth: 'none'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    textField: {
        flex: 1
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'right',
        width: '100%',
        marginTop: '20px',
        gap: '10px'
    },
    examplePromptList: {
        textAlign: 'left',
        listStyleType: 'none',
        padding: '4px 0'
    },
    examplePromptItem: {
        padding: '4px 0',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#f0f0f0'
        }
    },
    examplePromptIcon: {
        marginRight: '8px'
    }
}))

function RefinementDialog ({
                              open,
                              handleClose,
                              userQuery,
                              setUserQuery,
                              countWords,
                              selectedFileData,
                              handleQuerySubmit
                          }) {
    const classes = useStyles()
    const initialQueryRef = useRef(userQuery)

    useEffect(() => {
        initialQueryRef.current = userQuery
    }, [open, userQuery])

    const hasChanges = userQuery !== initialQueryRef.current

    let promptExamples = [
        'Anonymisiere das Dokument gemäß DSGVO / GDPR',
        'Anonymisiere alle Finanzdaten ausgenommen Währungskurse',
        'Anonymisiere alle personenbezogenen Daten von Mitarbeitenden',
        'Anonymisiere die unternehmensinternen Informationen wie z.B. Geschäftsgeheimnisse'
    ]
    if (selectedFileData.sample_anonymization_prompts?.Sample_Prompts) {
        promptExamples = ['Mache das Dokument GDPR-konform']
        promptExamples.push(...selectedFileData.sample_anonymization_prompts.Sample_Prompts)
    }

    const handlePromptClick = (prompt) => {
        setUserQuery(prompt)
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose()
                }
            }}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="form-dialog-title">Anonymisierung verfeinern</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    label="Geben Sie ihren Prompt ein (mind. 4 Wörter, DE/EN)"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={8}
                    value={userQuery}
                    onChange={(e) => setUserQuery(e.target.value)}
                    margin="normal"
                    className={classes.textField}
                />
                <div className={classes.buttonContainer}>
                    <Button
                        isCTA={countWords(userQuery) >= 4 && !selectedFileData.busySince && hasChanges}
                        onClick={handleQuerySubmit}
                        disabled={countWords(userQuery) < 4 || selectedFileData.busySince || !hasChanges}
                        slug='Senden'
                        label='Senden'
                    />
                    <Button
                        onClick={handleClose}
                        slug='Abbrechen'
                        label='Abbrechen'
                        isCTA={true}
                    />
                </div>
                <Typography variant="h5" style={{ textAlign: 'center', margin: '1rem 0' }} />
                <ul className={classes.examplePromptList}>
                    <li style={{ fontWeight: 'bold' }}>Beispiele für effiziente Prompts:</li>
                    {promptExamples.map((prompt, index) => (
                        <li key={index} className={classes.examplePromptItem} onClick={() => handlePromptClick(prompt)}>
                            <IconButton size="small" className={classes.examplePromptIcon}>
                                <TouchAppIcon fontSize="small" />
                            </IconButton>
                            {prompt}
                        </li>
                    ))}
                </ul>
            </DialogContent>
        </Dialog>
    )
}

RefinementDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    userQuery: PropTypes.string.isRequired,
    setUserQuery: PropTypes.func.isRequired,
    countWords: PropTypes.func.isRequired,
    selectedFileData: PropTypes.any.isRequired,
    handleQuerySubmit: PropTypes.func.isRequired
}

export default RefinementDialog
