import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Pagination from './Pagination'
import DocumentTeaser from './Teaser/DocumentTeaser'
import ChatBot from './ChatBot'
import { IconButton } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp' // Import the download icon from Material-UI icons
import EventIcon from '@material-ui/icons/Event' // Year icon
import GavelIcon from '@material-ui/icons/Gavel' // Court icon
import LanguageIcon from '@material-ui/icons/Language'
import { useDispatch, useSelector } from 'react-redux'
import { addResult, removeResult } from '../../../../slices/selectedChatSearchDocumentsSlice'
import getCourtImage from './CourtImgMapping'

function SearchResult ({ results, totalItems, itemsPerPage, handlePageChange, currentQuery }) {
  console.log('results', results)
  const [chatOpen, setChatOpen] = useState(false)
  const dispatch = useDispatch()
  const selectedChatResults = useSelector((state) => state.selectedChatResults)
  const handleSelect = (documentId, isChecked) => {
    if (isChecked) {
      dispatch(addResult(documentId))
    } else {
      dispatch(removeResult(documentId))
    }
  }
  // Function to get color
  const getColor = (index) => {
    const colors = ['#3672b0', '#bad8f6', '#7191a2', '#6298ec']
    return colors[index % colors.length]
  }

  const downloadFile = (url) => {
    window.open(url, '_blank')
  }

  const handleChatOpen = () => {
    setChatOpen(true)
  }

  const handleChatClose = () => {
    setChatOpen(false)
  }

  return (
        <div className="results-section">
          <div className="controls-section">
            <Pagination
                totalResults={totalItems}
                resultsPerPage={itemsPerPage}
                paginate={handlePageChange}
            />
            <button
                className={`search-button ${selectedChatResults.length === 0 ? '' : 'action'}`}
                onClick={handleChatOpen}
                disabled={selectedChatResults.length === 0}
            >
              {
                selectedChatResults.length === 0 ? 'Dokumente zum Chatten hinzufügen' : `Chatten mit ${selectedChatResults.length} ${selectedChatResults.length === 1 ? 'Dokument' : 'Dokumenten'}`
              }
            </button>
            <ChatBot
                query={currentQuery}
                open={chatOpen}
                onClose={handleChatClose}
                documents={selectedChatResults}
            />

          </div>
            {[...results].map((result, index) => {
              const downloadUrl = 'https://storage.googleapis.com/verdicts-storage-ch/upload_GCP_04122023_/' + result.document_id + '.pdf'
              const percentages = Object.entries(result)
                  .filter(([key, value]) => key.startsWith('sub_query'))
                  .map(([key, value]) => value)
              // Filter relevant_paragraphs key
              const relevantParagraphs = result.relevant_paragraphs

              return (
                  <div key={index} className="result-item">
                    <div className="info-bar">
                      <span><EventIcon className="info-icon"/>{result.year}</span>
                      <span>
                        <img
                            src={getCourtImage(result.court_meta_de)}
                            alt="Court"
                            className="info-icon"
                            title={result.court_meta_de} // This shows the text on hover
                        />
                      </span>
                      <span><LanguageIcon className="info-icon"/>{result.language}</span>
                    </div>

                    <div className="probability-bar-wrapper">
                      <div className="probability-bar">
                        {percentages.map((percentage, i) => (
                            <Tooltip
                                title={`Relevance of query ${1 + i} to this result = ${(percentage * 100).toFixed(2)}%`}
                                key={i}>
                              <div
                                  className="probability-segment"
                                  style={{ width: `${percentage * 100}%`, backgroundColor: getColor(i) }}
                              />
                            </Tooltip>
                        ))}
                      </div>
                    </div>
                    <DocumentTeaser relevantParagraphs={relevantParagraphs}/>
                    <div className="document-header">
                      <IconButton onClick={() => downloadFile(downloadUrl)} aria-label="download">
                        <DownloadIcon/>
                      </IconButton>
                      <span
                          className="document-title">Overall similarity={((1 - result.similarity_score) * 100).toFixed(2)}%</span>
                      <input type="checkbox"
                             style={{ margin: '0 10px' }}
                             id={`checkbox-${result.document_id}`}
                             checked={selectedChatResults.includes(result.document_id)}
                             onChange={(e) => handleSelect(result.document_id, e.target.checked)}/>
                      <div className="checkbox-container">
                        <label htmlFor={`checkbox-${result.document_id}`}>Zum Chat hinzufügen</label>
                      </div>
                    </div>
                  </div>
              )
            })}
        </div>
  )
}

SearchResult.propTypes = {
  results: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  currentQuery: PropTypes.string.isRequired
}

export default SearchResult
