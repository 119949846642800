// SecureFileStatusManager.js
import { deleteField, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase/firebase' // Adjust the import path based on your project structure

class SecureFileStatusManager {
    // Sets the file as busy with the current Firestore server timestamp if the user owns the file
    static async setFileBusy (userId, fileId) {
        const fileRef = doc(db, 'files', fileId)
        try {
            const fileDoc = await getDoc(fileRef)
            if (!fileDoc.exists() || fileDoc.data().user_id !== userId) {
                console.error('File does not exist or user does not have permission.')
                return
            }

            // Check if the file is already marked as busy
            const fileData = fileDoc.data()
            if (fileData.busySince) {
                // Throw a custom error indicating the file is already busy
                throw new Error('FileBusyError')
            }

            await updateDoc(fileRef, {
                busySince: serverTimestamp()
            })
            // console.log(`File ${fileId} is now set as busy.`)
        } catch (error) {
            console.error('Error setting file as busy:', error)
            throw error // Propagate the error to be handled by the caller
        }
    }

    // Retrieves the file's status; returns false or the seconds it has been busy
    static async getStatus (userId, fileId) {
        // console.log('userId', userId)
        // console.log('fileId', fileId)
        const fileRef = doc(db, 'files', fileId)
        try {
            const fileDoc = await getDoc(fileRef)
            if (!fileDoc.exists() || fileDoc.data().user_id !== userId) {
                // console.log('fileDoc', fileDoc.data())
                // console.log('userId', userId)
                console.error('File does not exist or user does not have permission.')
                return false
            }

            const fileData = fileDoc.data()
            if (fileData.busySince) {
                const now = new Date()
                const busySinceDate = fileData.busySince.toDate()
                return Math.round((now - busySinceDate) / 1000)
            }
            return false
        } catch (error) {
            console.error('Error retrieving file status:', error)
            return false
        }
    }

    // Clears the busy status of the file if the user owns the file
    static async clearFileBusy (userId, fileId) {
        const fileRef = doc(db, 'files', fileId)
        try {
            const fileDoc = await getDoc(fileRef)
            if (!fileDoc.exists() || fileDoc.data().user_id !== userId) {
                console.error('File does not exist or user does not have permission.')
                return
            }

            // Option 1: Set busySince to null
            // await updateDoc(fileRef, { busySince: null });

            // Option 2: Remove busySince field
            await updateDoc(fileRef, { busySince: deleteField() })
        } catch (error) {
            console.error('Error clearing file busy status:', error)
        }
    }
}

export default SecureFileStatusManager
