export const anonymizationImportanceMapping = {
  Klagepartei: 'Stufe 1 - Einfache Anonymisierung',
  Gegenpartei: 'Stufe 1 - Einfache Anonymisierung',
  Beklagter: 'Stufe 1 - Einfache Anonymisierung',
  Beschwerdeführer: 'Stufe 1 - Einfache Anonymisierung',
  Privatkläger: 'Stufe 1 - Einfache Anonymisierung',
  Bundesanwaltschaft: 'Stufe 2 - Erweiterte Anonymisierung',
  Beschuldigter: 'Stufe 1 - Einfache Anonymisierung',
  Beschuldigte: 'Stufe 1 - Einfache Anonymisierung',
  Verteidigung: 'Stufe 2 - Erweiterte Anonymisierung',
  Gericht: 'Stufe 4 - Umfassende Anonymisierung',
  Richter: 'Stufe 4 - Umfassende Anonymisierung',
  Opfer: 'Stufe 1 - Einfache Anonymisierung',
  Zeuge: 'Stufe 1 - Einfache Anonymisierung',
  Gerichtsschreiber: 'Stufe 4 - Umfassende Anonymisierung',
  Gerichtsschreiberin: 'Stufe 4 - Umfassende Anonymisierung',
  'Öffentliche-Figuren': 'Stufe 4 - Umfassende Anonymisierung',
  Behörden: 'Stufe 4 - Umfassende Anonymisierung',
  Sachverständige: 'Stufe 2 - Erweiterte Anonymisierung',
  Beschwerdegegnerin: 'Stufe 1 - Einfache Anonymisierung',
  Bezugspersonen: 'Stufe 2 - Erweiterte Anonymisierung',
  Staatsanwaltschaft: 'Stufe 2 - Erweiterte Anonymisierung',
  Medien: 'Stufe 3 - Vollständige Anonymisierung',
  Zuschauer: 'Stufe 3 - Vollständige Anonymisierung',
  'Mitarbeiter-des-Gerichts': 'Stufe 4 - Umfassende Anonymisierung',
  Übersetzer: 'Stufe 4 - Umfassende Anonymisierung',
  Minderjährige: 'Stufe 1 - Einfache Anonymisierung',
  Behinderte: 'Stufe 1 - Einfache Anonymisierung',
  'Fallbezogene-Informationen': 'Stufe 4 - Umfassende Anonymisierung',
  Bild: 'Stufe 4 - Umfassende Anonymisierung',
  Grafik: 'Stufe 4 - Umfassende Anonymisierung'
}
export const anonymizationLevelsInfo = {
  'Stufe 1 - Einfache Anonymisierung: Klagepartei, Gegenpartei, Opfer, Zeuge, Minderjährige, Menschen mit Behinderungen': 'Umfasst die Anonymisierung von natürlichen und juristischen Personen, die unmittelbar am Verfahren beteiligt sind. Dabei handelt es sich nicht um Personen, die Teil der operativen Rechtsprechung sind, wie z.B. Richter:innen.',
  'Stufe 2 - Erweiterte Anonymisierung: + (Verteidigung, Sachverständige, Bezugspersonen)': 'Erweitert die Anonymisierung auf Personen, die durch ihre Rolle oder Beteiligung am Verfahren einer differenzierten Anonymisierungsstrategie bedürfen, einschließlich Verteidigung, Sachverständige und nahe Bezugspersonen.',
  'Stufe 3 - Vollständige Anonymisierung: + (Medien, Zuschauer)': 'Betrifft die Anonymisierung von Entitäten, die nicht direkt am Verfahren teilnehmen, aber potenziell identifizierbar sind, darunter Medienvertreter und Zuschauer.',
  'Stufe 4 - Umfassende Anonymisierung: + (Gericht, Gerichtsschreiber:innen, öffentliche Figuren, Behörden, Mitarbeiter:innen des Gerichts, Übersetzer:innen, fallbezogene Informationen)': 'Gilt für Entitäten, die üblicherweise öffentlich sind, einschließlich Gericht, Gerichtsschreiber:innen, öffentliche Figuren, Behörden, Gerichtsmitarbeiter:innen, Übersetzer:innen und wesentliche, fallbezogene Informationen, die für das Verständnis des Urteils kritisch sind.'
}

export const anonymizationLevelsExplanation = 'Die Anonymisierungsstufen bestimmen den Grad der Anonymisierung. Jede Stufe hat spezifische Kriterien, die Auswirkungen auf die Anonymisierung bestimmter personenbezogener Informationen haben.'
