import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
import { useDispatch } from 'react-redux'
import { setFooterHeight } from '../../slices/layoutSlice'

export default function Footer () {
  const currentYear = new Date()?.getFullYear()
  const footerRef = useRef(null)
  const dispatch = useDispatch()

  const footerNavigation = [
    {
      title: 'Impressum',
      slug: 'impressum'
    },
    {
      title: 'Datenschutzerklärung',
      slug: 'privacy'
    },
    {
      title: 'LinkedIn',
      slug: 'https://www.linkedin.com/company/elephant-labs-gmbh'
    }
  ]

  useEffect(() => {
    const footerHeight = footerRef.current.clientHeight
    dispatch(setFooterHeight(footerHeight))
  }, [])

  return (
    <footer className={styles.footer} ref={ footerRef}>
      <nav aria-label='Footer'>
        <ul>
          <li>© ElephantLabs {currentYear}</li>
          {footerNavigation?.map((item) => (
            <li key={item?.slug}>
              {item?.slug.includes('https://') ? (
                <Link to={item?.slug} target='_blank' rel='noopener noreferrer'>{item?.title}</Link>
              ) : (
                <Link to={item?.slug}>{item?.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  )
}
