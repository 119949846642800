/**
 * Fetch request function to get data from the backend
 * @param {string} query - The search query.
 * @param {Array} filters - The selected filters.
 * @returns {Promise} - The promise resolving to data from the backend.
 */
import { API_ENDPOINTS } from '../../../../config/apiConfig'

const fetchChat = async (history, documents, userIdToken) => {
  const apiUrl = API_ENDPOINTS.chat
  // Construct the search payload with query and filters
  const payloadSQL = {
    history,
    documents
  }
  try {
    // Make the HTTP POST request using fetch API
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userIdToken}`
      },
      body: JSON.stringify(payloadSQL)
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    // Parse the response body as JSON
    const data = await response.json()
    return data
  } catch (error) {
    console.error('There was a problem fetching the data: ', error)
    // Handle the error according to your application's needs
    throw error
  }
}

export default fetchChat
