// RateLimitDialog.js
import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

function RateLimitDialog ({ open, onClose, onContact, timeToRetry, numberPages }) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="rate-limit-dialog-title">
            <DialogTitle id="rate-limit-dialog-title">Limit erreicht</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Sie haben Ihr Anfragelimit überschritten ({numberPages} Seiten). Bitte warten Sie {timeToRetry}, bevor Sie es erneut versuchen, oder kontaktieren Sie den Vertrieb, um Ihr Limit zu erhöhen.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Schließen
                </Button>
                <Button onClick={onContact} color="primary" autoFocus>
                    Kontakt aufnehmen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

RateLimitDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onContact: PropTypes.func.isRequired,
    timeToRetry: PropTypes.any,
    numberPages: PropTypes.number
}

export default RateLimitDialog
