import { createSlice } from '@reduxjs/toolkit'

const selectedResultsSlice = createSlice({
  name: 'selectedResults',
  initialState: [],
  reducers: {
    addResult: (state, action) => {
      // Only add the document_id to the state if it's not already included
      if (!state.includes(action.payload)) {
        return [...state, action.payload]
      }
      return state
    },
    removeResult: (state, action) => {
      // Create a new state excluding the one to be removed
      return state.filter((documentId) => documentId !== action.payload)
    },
    clearResults: () => []
  }
})

export const { addResult, removeResult, clearResults } = selectedResultsSlice.actions
export default selectedResultsSlice.reducer
