import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import styles from './Highlight.module.scss'

export default function Highlight ({ reverseColumns, item, id, hasCTA }) {
  return (
    <section className={`${styles.highlight} ${reverseColumns ? styles.reverse : undefined}`} id={id}>
      <img src={item?.media} alt={item?.title} />
      <div>
        <h2>{item?.title}</h2>
        <p>{item?.text}</p>
        <ul>
          {item?.facts?.map((fact, index) => (
            <li key={index}>{fact}</li>
          ))}
        </ul>
        {hasCTA ? (
          <div>
              <Button label='Jetzt Kontakt aufnehmen →' slug='/#kontakt' isCTA />
          </div>
        ) : null}
      </div>
    </section>
  )
}

Highlight.propTypes = {
  hasCTA: PropTypes.bool,
  reverseColumns: PropTypes.bool,
  item: PropTypes.object.isRequired,
  id: PropTypes.string
}
