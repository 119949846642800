import { getDownloadURL, ref } from 'firebase/storage'
import { db, storage } from '../../../../firebase/firebase'
import { doc, getDoc } from 'firebase/firestore'

async function secureDownloadFile (userId, fileId) {
  const decryptedData = await getDecryptedData(userId, fileId)
  const blob = new Blob([decryptedData], { type: 'application/pdf' })

  return URL.createObjectURL(blob)
}

async function getDecryptedData (userId, fileId) {
  // Get the file document
  const fileDocumentRef = doc(db, 'files', fileId)
  const fileDocumentSnapshot = await getDoc(fileDocumentRef)
  const fileDocument = fileDocumentSnapshot.data()

  // Get the encrypted file data
  const fileRef = ref(storage, `userFiles/${userId}/${fileDocument.parent_id}/${fileDocument.name}`)
  const fileUrl = await getDownloadURL(fileRef)
  const response = await fetch(fileUrl)
  const fileData = await response.arrayBuffer()

  // Check if encryption keys exist
  if (fileDocument.cryptoKey && fileDocument.iv) {
    // Get the crypto key and initialization vector
    const rawKey = new Uint8Array(fileDocument.cryptoKey)
    const iv = new Uint8Array(fileDocument.iv)

    // Import the crypto key
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        rawKey,
        { name: 'AES-GCM', length: 256 },
        true,
        ['decrypt']
    )

    // Decrypt the file data
    const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        fileData
    )

    return new Uint8Array(decryptedData)
  } else {
    // Return the unencrypted file data directly if no keys are present
    return new Uint8Array(fileData)
  }
}

export { secureDownloadFile }
