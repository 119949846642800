import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from './LoadingSpinner'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

function DocumentTeaser ({ relevantParagraphs }) {
  const documentUrl = 'https://upload.wikimedia.org/wikipedia/commons/c/c3/LibreOffice_Writer_6.3.png'
  const [loading, setLoading] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)

// Split the value of the relevant paragraphs into an array of paragraphs - split by ||||
  const relevantParagraphsArray = relevantParagraphs.split('||||')

  useEffect(() => {
    const img = new Image()
    img.onload = () => setLoading(false)
    img.onerror = () => {
      console.error('Error loading image')
      setLoading(false)
    }
    img.src = documentUrl
  }, [documentUrl])

    const handleClickOpen = () => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <div style={{ textAlign: 'left', margin: '20px' }}>
                {relevantParagraphsArray.slice(0, 2).map((paragraphArray, index) => (
                    <div key={index} onClick={handleClickOpen} className="querySnippet"
                         title={`related paragraph ${index + 1} - Click to view more`}>
                        <p>
                            <span className="bulletPoint">•</span> {paragraphArray}
                        </p>
                    </div>
                ))}
            </div>

            <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle>Search Results</DialogTitle>
                 <DialogContent>
                     {relevantParagraphsArray.map((paragraphArray, index) => (
                         <div key={index} onClick={handleClickOpen} className="querySnippet">
                             <p>
                                 <span className="bulletPoint">•</span> {paragraphArray}
                             </p>
                         </div>
                     ))}
                 </DialogContent>
            </Dialog>
        </div>
    )
}

DocumentTeaser.propTypes = {
    relevantParagraphs: PropTypes.string.isRequired
}

export default DocumentTeaser
