/**
 * Fetch request function to get data from the backend
 * @param {string} query - The search query.
 * @param {Array} filters - The selected filters.
 * @param {string} userIdToken - The user's ID token.
 * @returns {Promise} - The promise resolving to data from the backend.
 */
import { API_ENDPOINTS } from '../../../../config/apiConfig'

const fetchClusters = async (results, combinedQuery, userIdToken, filters, selectedFilters) => {
    const apiUrl = API_ENDPOINTS.cluster
    // if selectedFilters is empty, set it to an empty object
    selectedFilters = selectedFilters || {}
    filters = filters || []
    // Construct the search payload with query and filters
    const payload = {
        query_results: results,
        combined_query: combinedQuery,
        filters,
        selectedFilters
    }
    try {
        // Make the HTTP POST request using fetch API
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf8',
                Accept: 'application/json',
                Authorization: `Bearer ${userIdToken}`
            },
            body: JSON.stringify(payload)
        })
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return data
    } catch (error) {
        console.error('There was a problem fetching the data: ', error)
        // Handle the error according to your application's needs
        throw error
    }
}

export default fetchClusters
