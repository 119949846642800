import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import Button from '../../../../components/Button/Button'

function AnonymizationErrorDialog ({ open, onClose, onContact, errorMessage }) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="anonymization-error-dialog-title">
            <DialogTitle id="anonymization-error-dialog-title">Anonymisierungsfehler</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Der folgende Fehler ist aufgetreten. Bitte warten Sie eine Minute und versuchen Sie es erneut, oder löschen Sie die Datei und laden Sie sie erneut hoch. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Support. Hinweis: OCR wird noch nicht unterstützt.
                </DialogContentText>
                <DialogContentText style={{ marginTop: '16px' }}>
                    {errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    label='Schließen'
                    isCTA={true}
                />
                <Button
                    onClick={onContact}
                    label='Support kontaktieren'
                    isCTA={true}
                />
            </DialogActions>
        </Dialog>
    )
}

AnonymizationErrorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onContact: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired
}

export default AnonymizationErrorDialog
