import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAuth } from 'firebase/auth'

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, { getState }) => {
  const auth = getAuth()
  const currentUser = auth.currentUser
  if (currentUser) {
    const userIdToken = await currentUser.getIdToken(true) // force refresh
    return { userIdToken }
  } else {
    throw new Error('No current user')
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userId: null,
    userScreenName: null,
    userIdToken: null,
    pdfs: {} // PDF data keyed by fileId
  },
  reducers: {
    setUserIdScreenName: (state, action) => {
      const { userId, userScreenName, userIdToken } = action.payload
      state.userId = userId
      state.userScreenName = userScreenName
      state.userIdToken = userIdToken
    },
    logout: (state) => {
      state.userId = null
      state.userScreenName = null
      state.userIdToken = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.userIdToken = action.payload.userIdToken
    })
  }
})

export const { setUserIdScreenName, logout } = authSlice.actions

export default authSlice.reducer
