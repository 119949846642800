import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pdfViewer: {
    isActive: false,
    blobUrl: null
  }
}

const collaborativeDocumentAnonymizationStateSlice = createSlice({
  name: 'collaborativeDocumentAnonymizationState',
  initialState,
  reducers: {
    // Action to set the active state of the PDFViewer
    setPDFViewerActive: (state, action) => {
      state.pdfViewer.isActive = action.payload
    },
    // Action to set the blobUrl of the PDF file
    setPDFViewerBlobUrl: (state, action) => {
      state.pdfViewer.blobUrl = action.payload
    }
  }
})

export const { setPDFViewerActive, setPDFViewerBlobUrl } = collaborativeDocumentAnonymizationStateSlice.actions

export default collaborativeDocumentAnonymizationStateSlice.reducer
