async function encryptData (arrayBuffer) {
  // Generate a random key for encryption
  const cryptoKey = await window.crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  )

  // Export the cryptoKey to raw data
  const rawKeyBuffer = await window.crypto.subtle.exportKey('raw', cryptoKey)
  const rawKey = new Uint8Array(rawKeyBuffer) // Convert to Uint8Array

  // Define initialization vector
  const iv = window.crypto.getRandomValues(new Uint8Array(12))

  // Encrypt the data
  const encryptedData = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    cryptoKey,
    arrayBuffer
  )

  return { encryptedData, rawKey, iv }
}

export { encryptData }
