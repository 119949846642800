import { getFirestore, collection, doc, setDoc, Timestamp, updateDoc, where, getDocs, query } from 'firebase/firestore'
import { createFolder } from './createFolder'

const db = getFirestore()

export const createUser = async (email, userId, termsAccepted, firstName, lastName, organization, setNewPasswordFlag) => {
  const adminEmailDomains = ['@elephantlabs.ai']
  try {
    const usersRef = collection(db, 'users')
    const snapshot = await getDocs(query(usersRef, where('email', '==', email)))

    if (!snapshot.empty) {
      return true // User already exists
    }

    const userRef = doc(usersRef, userId)
    const userLevel = adminEmailDomains.some(domain => email.includes(domain)) ? 'admin' : 'basic'
    const userData = {
      email,
      created_at: Timestamp.now(),
      first_name: firstName,
      last_name: lastName,
      organization_name: organization,
      main_folder_id: null,
      local_folder_path: null,
      processes: [],
      user_level: userLevel,
      termsAccepted,
      setNewPassword: setNewPasswordFlag // This field indicates if a new password must be set on first login
    }

    await setDoc(userRef, userData)

    const mainFolder = await createFolder(userRef.id, 'Root')
    await updateDoc(userRef, { main_folder_id: mainFolder.id })

    return true
  } catch (error) {
    console.error('Error creating user: ', error)
    return false
  }
}
