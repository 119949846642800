import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { handleMouseOver } from './annotationComponents/handleMouseOver'
import { handleMouseOut } from './annotationComponents/handleMouseOut'

function Annotations ({ currentPage, pdfViewerRef, anonymizations, annotationStatus, setAnnotationStatus, viewMode }) {
  const [annotations, setAnnotations] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 })
  const [currentAnnotation, setCurrentAnnotation] = useState({})

  useEffect(() => {
    // Combine annotations for the current page and set their color based on anonymize status
    const pageAnnotations = (anonymizations.ners || [])
      .filter(annotation => annotation.page === currentPage - 1)
      .map(annotation => ({
        ...annotation.bbox,
        id: annotation.entity,
        name: annotation.entity,
        category: annotation.category,
        annonymize: annotationStatus[annotation.entity]?.annonymize || false
      }))

    setAnnotations(pageAnnotations)
  }, [currentPage, anonymizations])

  const handleAnnotationClick = (annotation) => {
    setAnnotationStatus((prevStatus) => {
      // Create a new object for the new state
      const newStatus = { ...prevStatus }
      // Find the annotation object by its id and toggle its annonymize value
      if (newStatus[annotation.id]) {
        newStatus[annotation.id] = {
          ...newStatus[annotation.id],
          annonymize: !newStatus[annotation.id].annonymize
        }
      }
      return newStatus
    })
    setAnnotations((prevAnnotations) => {
      return prevAnnotations.map((prevAnnotation) => {
        if (prevAnnotation.id === annotation.id) {
          return {
            ...prevAnnotation,
            annonymize: !prevAnnotation.annonymize
          }
        }
        return prevAnnotation
      })
    }
    )
  }

  return (
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100, pointerEvents: 'none' }}>
        {showPopup && (
            <div style={{ position: 'absolute', left: `${popupPosition.x}px`, top: `${popupPosition.y}px` }} className="popup-text">
              <div>
                Kategorie<br/><b>{currentAnnotation.category}</b>
              </div>
            </div>
        )}

        <svg className="annotations-overlay">
          {annotations.map((annotation, index) => {
            return (
                <rect
                    key={`${annotation.id}-${index}`}
                    x={annotation.x0}
                    y={annotation.y0}
                    width={annotation.x1 - annotation.x0}
                    height={annotation.y1 - annotation.y0}
                    fill={viewMode === 'blackout' && annotation.annonymize === true ? 'black' : 'transparent'}
                    stroke={annotation.annonymize === true ? 'black' : 'gray'}
                    strokeWidth={annotation.annonymize === true ? 0.9 : 1 }
                    strokeDasharray={annotation.annonymize === true ? '0' : '8,3'}
                    className="annotation"
                    onClick={() => handleAnnotationClick(annotation)}
                    onMouseOver={handleMouseOver(annotation, setPopupPosition, setShowPopup, setCurrentAnnotation, pdfViewerRef)}
                    onMouseOut={handleMouseOut(setShowPopup)}
                />
            )
          })}
        </svg>
      </div>
  )
}

Annotations.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pdfViewerRef: PropTypes.object.isRequired,
  anonymizations: PropTypes.object.isRequired,
  annotationStatus: PropTypes.object.isRequired,
  setAnnotationStatus: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired
}
export default Annotations
