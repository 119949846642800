import React, { useEffect, useId, useState, useRef } from 'react'
import { HashLink } from 'react-router-hash-link'
import Avatar from '../Avatar/Avatar'
import Button from '../Button/Button'
import logo from '../../assets/images/logo.svg'
import styles from './Header.module.scss'
import { useDispatch } from 'react-redux'
import { setHeaderHeight } from '../../slices/layoutSlice'
import { useNavigate } from 'react-router-dom'

export default function Header () {
  const navigationId = useId()
  const headerRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const handleOpenClose = () => setIsOpen(!isOpen)
  const handlePlaygroundClick = () => {
    setIsOpen(false)
    navigate('/playground')
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const primaryNavigation = [
    {
      title: 'Lösungen',
      slug: 'loesungen'
    },
    {
      title: 'Services',
      slug: 'services'
    },
    {
      title: 'Über uns',
      slug: 'ueber-uns'
    },
    {
      title: 'Kontakt',
      slug: 'kontakt'
    }
  ]

  useEffect(() => {
    const headerHeight = headerRef.current.clientHeight
    dispatch(setHeaderHeight(headerHeight))
  }, [])

  return (
    <div className={`${styles.header} ${isOpen ? styles.open : undefined}`} id='header' ref={headerRef}>
      <header>
        <span className={styles.logo}>
          <HashLink smooth to={'/#'}>
            <img src={logo} alt='ElephantLabs' />
          </HashLink>
        </span>
        <div>
          <Avatar />
          <button
            aria-label={isOpen ? 'Navigation schließen' : 'Navigation öffen'}
            aria-controls={navigationId}
            aria-expanded={isOpen}
            onClick={handleOpenClose}
          >
            {isOpen ? '' : ''}
          </button>
        </div>
      </header>
      {isLoaded ? (
        <nav aria-label='Main'>
          <ul id={navigationId}>
            {primaryNavigation?.map((item) => (
              <li key={item?.slug}>
                <HashLink
                  smooth
                  to={`/#${item?.slug}`}
                  onClick={handleOpenClose}
                >
                  {item?.title}
                </HashLink>
              </li>
            ))}
            <li>
              <Button label='Playground ↗' onClick={handlePlaygroundClick} isCTA />
            </li>
            <li>
              <Avatar />
            </li>
          </ul>
        </nav>
      ) : null}
    </div>
  )
}
