import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useTypewriter from 'react-typewriter-hook'
import styles from './WordList.module.scss'

export default function WordList ({ words }) {
  const [items] = useState(words ?? [])
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const word = useTypewriter(items[currentItemIndex])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentItemIndex((currentItemIndex + 1) % items.length)
    }, 6000)

    return () => {
      clearInterval(intervalId)
    }
  }, [currentItemIndex])

  return (
    <span className={styles.wordlist}>{word}</span>
  )
}

WordList.propTypes = {
  words: PropTypes.array.isRequired
}
