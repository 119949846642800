import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import authReducer from './slices/authSlice'
import selectedResultsReducer from './slices/selectedChatSearchDocumentsSlice'
import selectedFileSlice from './slices/FolderTreeDataStateSlice'
import collaborativeDocumentAnonymizationStateSlice from './slices/collaborativeDocumentAnonymizationStateSlice'
import layoutReducer from './slices/layoutSlice'

// Configuring redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // Specify which reducers to persist
}

const rootReducer = combineReducers({
  auth: authReducer,
  selectedChatResults: selectedResultsReducer,
  selectedFileSlice,
  collaborativeDocumentAnonymizationStateSlice,
  layout: layoutReducer
  // Add more reducers if needed
})

// Wrap root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Configure store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer
  // Add any middleware or enhancers here
})

// Create a persistor for the store
export const persistor = persistStore(store)

export default store
