import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, Tooltip, IconButton } from '@material-ui/core'
import manualAdditionHandler from './ManualAdditionHandler'
import { updateAnonymizationMapping } from '../../../../../slices/FolderTreeDataStateSlice'
import { useDispatch } from 'react-redux'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useNavigate, useLocation } from 'react-router-dom'

const PDFViewerContextMenu = ({
                                  contextMenuPosition,
                                  handleClose,
                                  selectedText,
                                  extendedSelectedText,
                                  fileId,
                                  userIdToken,
                                  userId,
                                  selectedFileData,
                                  setIsLoading
                              }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const anonymizations = selectedFileData?.mapping
    const hasAnonymizationsData = anonymizations && anonymizations.ners

    const handlePrintToConsoleClick = () => {
        console.log(selectedText)
        handleClose()
    }

    const handleRedactOccurrencesClick = async () => {
        if (selectedText && fileId && userIdToken) {
            setIsLoading(true)
            try {
                const manualBoxes = await manualAdditionHandler(fileId, selectedText, userIdToken, userId, navigate, location)
                // console.log(manualBoxes)

                const oldMappings = selectedFileData?.mapping || {}
                const newMappings = { ...oldMappings, ners: [...(oldMappings.ners || []), ...manualBoxes] }

                dispatch(updateAnonymizationMapping({
                    fileId,
                    newAnonymization: newMappings
                }))
            } catch (error) {
                console.error('An error occurred while fetching manual boxes:', error)
            }
        } else {
            console.error('Required information is missing')
        }
        setIsLoading(false)
        handleClose()
    }

    const cleanText = (text) => {
        // Trim the text and remove punctuation from the start and end
        const trimmedText = text.trim()
        // Remove punctuation only if it's at the start or end
        const cleanedText = trimmedText.replace(/^['"`()„“,;!?]+|['"`()„“,;!?]+$/g, '')
        // const cleanedText = trimmedText.replace(/[.,;!?]+$/g, '')

        return cleanedText
    }
    // console.log('"', selectedText, '"')
    // console.log('"', extendedSelectedText, '"')
    // console.log('"', cleanText(extendedSelectedText), '"')

    const countWords = (text) => {
        return text.split(/\s+/).filter(word => word.length > 0).length
    }

    const getDisableReason = () => {
        if (!hasAnonymizationsData) {
            return 'Erst anonymisieren'
        }
        // if (selectedText.trim().length <= 1) {
        //     return 'Auswahl zu kurz'
        // }
        if (countWords(selectedText) > 1) {
            return ''
        }
        const cleanedExtendedText = cleanText(extendedSelectedText)
        if (cleanedExtendedText !== selectedText.trim() || cleanedExtendedText.length === 0) {
            return 'Kein vollständiges Wort oder Text'
        }
        return ''
    }

    const disableReason = getDisableReason()
    const isDisabled = disableReason !== ''

    return (
        <Menu
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenuPosition ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX } : undefined
            }
            keepMounted
            open={Boolean(contextMenuPosition)}
            onClose={handleClose}
        >
            {/* <MenuItem onClick={handlePrintToConsoleClick}>Print to Console</MenuItem> */}
            <Tooltip title={disableReason} placement="top">
                <span>
                    <MenuItem
                        onClick={handleRedactOccurrencesClick}
                        disabled={isDisabled}
                    >
                        Im gesamten Dokument schwärzen
                        {isDisabled && (
                            <IconButton size="small" className="info-icon">
                                <HelpOutlineIcon fontSize="inherit" />
                            </IconButton>
                        )}
                    </MenuItem>
                </span>
            </Tooltip>
        </Menu>
    )
}

PDFViewerContextMenu.propTypes = {
    contextMenuPosition: PropTypes.shape({
        mouseX: PropTypes.number.isRequired,
        mouseY: PropTypes.number.isRequired
    }),
    handleClose: PropTypes.func.isRequired,
    selectedText: PropTypes.string,
    extendedSelectedText: PropTypes.string,
    fileId: PropTypes.string.isRequired,
    userIdToken: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    selectedFileData: PropTypes.any,
    setIsLoading: PropTypes.func.isRequired
}

export default PDFViewerContextMenu
