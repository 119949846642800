import { ref, getDownloadURL } from 'firebase/storage'
import { storage, db } from '../../../../../../firebase/firebase'
import { doc, getDoc } from 'firebase/firestore'

async function downloadAndDecryptFile (userId, fileId) {
  // Get the file document
  const fileDocumentRef = doc(db, 'files', fileId)
  const fileDocumentSnapshot = await getDoc(fileDocumentRef)
  const fileDocument = fileDocumentSnapshot.data()

  // Get the file data
  const fileRef = ref(storage, `userFiles/${userId}/${fileDocument.parent_id}/${fileDocument.name}`)
  const fileUrl = await getDownloadURL(fileRef)
  const response = await fetch(fileUrl)
  const fileData = await response.arrayBuffer()

  // Check if encryption keys exist
  if (fileDocument.cryptoKey && fileDocument.iv) {
    // Keys exist, proceed with decryption

    // Get the crypto key and initialization vector
    const rawKey = new Uint8Array(fileDocument.cryptoKey)
    const iv = new Uint8Array(fileDocument.iv)

    // Import the crypto key
    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        rawKey,
        { name: 'AES-GCM', length: 256 },
        true,
        ['decrypt']
    )

    // Decrypt the file data
    const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        fileData
    )

    // Create a blob from the decrypted data
    const blob = new Blob([new Uint8Array(decryptedData)], { type: fileDocument.type })
    downloadBlob(blob, fileDocument.name)
  } else {
    // No keys, assume file is not encrypted and directly download

    const blob = new Blob([new Uint8Array(fileData)], { type: fileDocument.type })
    downloadBlob(blob, fileDocument.name)
  }
}

function downloadBlob (blob, fileName) {
  // Create a URL for the blob object and initiate a download
  const blobUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = blobUrl
  link.download = fileName
  document.body.appendChild(link) // Required for Firefox
  link.click()
  document.body.removeChild(link) // Clean up
}

export { downloadAndDecryptFile }
