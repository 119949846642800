function getCourtImage (courtName) {
    const cantonAbbreviations = [
        { name: 'Aargau', abbreviation: 'AG' },
        { name: 'Appenzell Ausserrhoden', abbreviation: 'AR' },
        { name: 'Appenzell Innerrhoden', abbreviation: 'AI' },
        { name: 'Basel-Land', abbreviation: 'BL' },
        { name: 'Basel-Stadt', abbreviation: 'BS' },
        { name: 'Bern', abbreviation: 'BE' },
        { name: 'Freiburg', abbreviation: 'FR' }, // German spelling: Freiburg, French spelling: Fribourg
        { name: 'Genf', abbreviation: 'GE' }, // French spelling: Genève
        { name: 'Glarus', abbreviation: 'GL' },
        { name: 'Graubünden', abbreviation: 'GR' }, // Italian spelling: Grigioni, Romansh spelling: Grischun
        { name: 'Jura', abbreviation: 'JU' },
        { name: 'Luzern', abbreviation: 'LU' },
        { name: 'Neuenburg', abbreviation: 'NE' }, // French spelling: Neuchâtel
        { name: 'Nidwalden', abbreviation: 'NW' },
        { name: 'Obwalden', abbreviation: 'OW' },
        { name: 'Schaffhausen', abbreviation: 'SH' },
        { name: 'Schwyz', abbreviation: 'SZ' },
        { name: 'Solothurn', abbreviation: 'SO' },
        { name: 'St. Gallen', abbreviation: 'SG' },
        { name: 'St.Gallen', abbreviation: 'SG' },
        { name: 'Thurgau', abbreviation: 'TG' },
        { name: 'Tessin', abbreviation: 'TI' }, // Italian spelling: Ticino
        { name: 'Uri', abbreviation: 'UR' },
        { name: 'Waadt', abbreviation: 'VD' }, // French spelling: Vaud
        { name: 'Wallis', abbreviation: 'VS' }, // French spelling: Valais
        { name: 'Zug', abbreviation: 'ZG' },
        { name: 'Zürich', abbreviation: 'ZH' },
        { name: 'Ticino', abbreviation: 'TI' }, // For Italian naming consistency
        { name: 'Valais', abbreviation: 'VS' }, // For French naming consistency
        { name: 'Vaud', abbreviation: 'VD' }, // For French naming consistency
        { name: 'Fribourg', abbreviation: 'FR' }, // For French naming consistency
        { name: 'Neuchâtel', abbreviation: 'NE' },
        { name: 'Eidgenossenschaft', abbreviation: 'CH' }
    ]
    for (let i = 0; i < cantonAbbreviations.length; i++) {
        if (courtName.includes(cantonAbbreviations[i].name)) {
            return `/img/kantone/${cantonAbbreviations[i].abbreviation}.png`
        }
    }
    // Fallback in case no match is found
    return 'src/assets/img/kantone/CH.png'
}

export default getCourtImage
