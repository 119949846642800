import { TextareaAutosize, Typography } from '@mui/material'
import React, { useState } from 'react'
import germanyFlag from '../../../../assets/images/icons/germany.png'
import Button from '../../../../components/Button/Button'

const DeAnonymizeTab = () => {
    const [text, setText] = useState('')

    const handleTextChange = (event) => {
        setText(event.target.value)
    }

    return (
        <div className="deanonymize-tab">
            <div className="content-wrapper">
                <div className="text-field-container">
                    <div className="input-section">
                        <TextareaAutosize
                            className="text-input"
                            minRows={7}
                            placeholder="....."
                            value={text}
                            onChange={handleTextChange}
                            style={{ paddingBottom: '30px' }} // Add padding to avoid collision with the icon
                        />
                        <span className="material-symbols-outlined left-side-icon">
                            cloud_upload
                        </span>
                        <span className="material-symbols-outlined right-side-icon">
                            content_paste
                        </span>
                    </div>
                    <div className="button-wrapper">
                        <Button
                            label="De-anonymisieren"
                            slug="De-anonymisieren"
                            isCTA
                            onClick={() => { /* Handle de-anonymize action */ }}
                        />
                        <div className="token-info">
                            <Typography>130 Tokens</Typography>
                            <img src={germanyFlag} alt="German Flag" />
                            <span className="material-symbols-outlined lock-icon">lock</span>
                        </div>
                    </div>
                </div>
                <div className="text-field-container">
                    <div className="output-section">
                        <TextareaAutosize
                            className="text-input"
                            minRows={7}
                            value={text}
                            readOnly
                            style={{ paddingBottom: '30px' }} // Add padding to avoid collision with the icon
                        />
                        <span className="material-symbols-outlined left-side-icon">
                            cloud_download
                        </span>
                        <span className="material-symbols-outlined right-side-icon">
                            content_copy
                        </span>
                    </div>
                    <div className="button-wrapper">
                        <Button
                            label="Kopieren"
                            slug="Kopieren"
                            isCTA
                            onClick={() => { /* Handle copy action */ }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeAnonymizeTab
