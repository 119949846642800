import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Button from '../../components/Button/Button'
import propTypes from 'prop-types'

const PasswordDialog = ({ newPasswordRequired, setNewPasswordRequired, handleNewPasswordSubmit, passwordRegex, newPassword, setNewPassword, isLoading }) => {
    const [confirmPassword, setConfirmPassword] = useState('')

    const isPasswordValid = newPassword.length > 0 && confirmPassword.length > 0 && newPassword === confirmPassword && passwordRegex.test(newPassword)

    return (
        <Dialog open={newPasswordRequired} onClose={() => setNewPasswordRequired(false)}>
            <DialogTitle>Neues Passwort festlegen</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px' // Adjust this value to your preference
                        }
                    }}
                    autoFocus
                    margin="dense"
                    label="Neues Passwort"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                    sx={{
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px' // Adjust this value to your preference
                        }
                    }}
                    margin="dense"
                    label="Passwort bestätigen"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleNewPasswordSubmit}
                    label="Bestätigen"
                    slug="Bestätigen"
                    isCTA={isPasswordValid && !isLoading}
                    disabled={!isPasswordValid || isLoading}
                />
            </DialogActions>
        </Dialog>
    )
}

PasswordDialog.propTypes = {
    newPasswordRequired: propTypes.bool.isRequired,
    setNewPasswordRequired: propTypes.func.isRequired,
    handleNewPasswordSubmit: propTypes.func.isRequired,
    passwordRegex: propTypes.object.isRequired,
    newPassword: propTypes.string.isRequired,
    setNewPassword: propTypes.func.isRequired,
    isLoading: propTypes.bool.isRequired
}

export default PasswordDialog
