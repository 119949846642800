// AnonymizationOptionsDialog.js
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel, makeStyles, Typography, TextField, Checkbox } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Button from '../../../../components/Button/Button'

const useStyles = makeStyles((theme) => ({
    optionLabel: {
        margin: theme.spacing(0.5, 0) // Reduced spacing
    },
    description: {
        marginBottom: theme.spacing(2) // Spacing for the description
    },
    checkboxLabel: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1) // Additional spacing above the checkbox for better visual separation
    }
}))

function AnonymizationOptionsDialog ({ open, onClose, onAnonymize, userQuery, setUserQuery }) {
  const classes = useStyles()
  const [selectedAnonymizationOption, setSelectedAnonymizationOption] = useState('')
  const [anonymizeImages, setAnonymizeImages] = useState(true)

  const handleAnonymizationButtonClick = () => {
    onAnonymize(selectedAnonymizationOption, anonymizeImages)
    onClose()
  }
  const countWords = (str) => {
    return str.split(' ').filter(function (n) { return n !== '' }).length
  }

  return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}
        >
            <DialogTitle>Auswahl der Anonymisierung</DialogTitle>
            <DialogContent>
                <Typography className={classes.description}>
                    Bitte wählen Sie die Art des Dokuments aus. Wählen Sie &quot;Gerichtsurteil&quot; für
                    deutschsprachige Gerichtsurteile oder &quot;Anderes Dokument&quot; für alle anderen Dokumente.
                </Typography>
                <Typography className={classes.description}>
                    Hinweis: Sie verwenden die Testversion von AnonLab. Die ersten 10 Seiten werden anonymisiert
                </Typography>
                <RadioGroup
                    aria-label="anonymization-option"
                    name="anonymizationOption"
                    value={selectedAnonymizationOption}
                    onChange={(e) => setSelectedAnonymizationOption(e.target.value)}
                >
                    <FormControlLabel
                        value="verdict_processor"
                        control={<Radio/>}
                        label={
                            <div>
                                Gerichtsurteil (Anonymisierung via konfigurierten Regeln)
                                <Tooltip
                                    title="Anonymisierung von deutschsprachigen Gerichtsurteilen auf Basis konfigurierter Regeln in 4 Stufen. Die Stufen basieren auf spezifischen Kriterien und geben vor, welche Informationen anonymisiert werden (z.B. Name von Richter:innen, Name von Zeugen, etc.).">
                                    <IconButton size="small" className="info-icon">
                                        <HelpOutlineIcon fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        className={classes.optionLabel}
                    />

                    <FormControlLabel
                        value="mistral_processor"
                        control={<Radio/>}
                        label={
                            <div>
                                Anderes Dokument (Anonymisierung via Prompt)
                                <Tooltip
                                    title="Anonymisierung von Dokumenten anhand individueller Anforderungen in Form eines Prompts. Der Nutzer definiert via Prompt, welche Informationen anonymisiert werden sollen oder welche Informationen nicht anonymisiert werden sollen (z.B. &quot;Anonymisiere alle Finanzdaten, außer Währungskurse&quot;).">
                                    <IconButton size="small" className="info-icon">
                                        <HelpOutlineIcon fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        className={classes.optionLabel}
                    />

                </RadioGroup>
                <div className={classes.checkboxLabel}>
                    <Checkbox
                        checked={anonymizeImages}
                        onChange={(e) => setAnonymizeImages(e.target.checked)}
                        color="primary"
                    />
                    <Typography variant="body1">Bilddaten (Unterschriften, Logos, etc.) anonymisieren</Typography>
                </div>
                <TextField
                    label="Geben Sie ihren Prompt ein (mind. 4 Wörter, DE/EN)"
                    variant="outlined"
                    fullWidth
                    value={userQuery}
                    onChange={(e) => setUserQuery(e.target.value)}
                    margin="normal"
                    disabled={selectedAnonymizationOption !== 'mistral_processor'}
                />

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    isCTA
                    label="Abbrechen"/>
                <Button
                    onClick={handleAnonymizationButtonClick}
                    disabled={!selectedAnonymizationOption ||
                        (selectedAnonymizationOption === 'mistral_processor' && countWords(userQuery) < 4)}
                    isCTA={!(!selectedAnonymizationOption ||
                        (selectedAnonymizationOption === 'mistral_processor' && countWords(userQuery) < 4))}
                    label="Anonymisieren"/>
            </DialogActions>
        </Dialog>
  )
}

AnonymizationOptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAnonymize: PropTypes.func.isRequired,
  userQuery: PropTypes.string.isRequired,
  setUserQuery: PropTypes.func.isRequired
}

export default AnonymizationOptionsDialog
