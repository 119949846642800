import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../firebase/firebase' // Ensure you have a reference to your Firestore database
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import TextField from '@mui/material/TextField'
import Button from '../../../components/Button/Button'
import styles from './AddNewUser.module.scss'
import { isMobile } from 'react-device-detect' // Create and import your own styles
import { createUser } from '../../../firebase/userOperations/createUser'

const AddNewUser = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [organization, setOrganization] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const navigate = useNavigate()
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/ // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character

    useEffect(() => {
        const checkAdminStatus = async () => {
            const user = auth.currentUser
            if (!user) {
                setError('No user logged in')
                setLoading(false)
                return
            }

            try {
                const userDoc = await getDoc(doc(db, 'users', user.uid))
                if (userDoc.exists() && userDoc.data().user_level === 'admin') {
                    setIsAdmin(true)
                } else {
                    setIsAdmin(false)
                }
            } catch (error) {
                console.error('Error fetching user data:', error)
                setError('Failed to fetch user data')
            } finally {
                setLoading(false)
            }
        }

        if (auth.currentUser) {
            checkAdminStatus()
        } else {
            // Listen for changes on auth object, which indicates potential user status updates
            const unsubscribe = auth.onAuthStateChanged(user => {
                if (user) {
                    checkAdminStatus()
                } else {
                    setLoading(false)
                }
            })

            // Cleanup the listener when the component unmounts
            return () => unsubscribe()
        }
    }, [auth]) // Depend on auth object, which contains the current user status

    if (loading) {
        return <p>Loading...</p>
    }

    if (!isAdmin && !loading) {
        navigate('/')
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setError('')
        setSuccess('')
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            await sendEmailVerification(userCredential.user)
            // Now pass the additional fields to your createUser function
            await createUser(email, userCredential.user.uid, false, firstName, lastName, organization, true)
            setSuccess(`User ${email} added successfully! The user will receive an email to verify their account.`)
            setEmail('')
            setPassword('')
            setFirstName('')
            setLastName('')
            setOrganization('')
        } catch (error) {
            console.error('Error creating user:', error)
            setError(`Failed to add user: ${error.message}`)
        }
    }

    return (
        <div className={styles.addNewUserForm}>
            <h2>Add New User</h2>
            <form>
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': { borderRadius: '20px' }
                    }}
                    label="Email Address of New User"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': { borderRadius: '20px' }
                    }}
                    label="Temporary Password - 8 characters, 1 uppercase, 1 lowercase, 1 special character"
                    type="text"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': { borderRadius: '20px' }
                    }}
                    label="First Name"
                    type="text"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': { borderRadius: '20px' }
                    }}
                    label="Last Name"
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': { borderRadius: '20px' }
                    }}
                    label="Organization"
                    type="text"
                    value={organization}
                    onChange={e => setOrganization(e.target.value)}
                    required
                    fullWidth
                />
                <Button
                    label="Add User"
                    slug="Add User"
                    disabled={!email || !password || !passwordRegex.test(password) || !firstName || !lastName || loading || !isAdmin || !organization}
                    isCTA={email && password && passwordRegex.test(password) && firstName && lastName && !loading && isAdmin && organization}
                    onClick={handleSubmit}
                />
            </form>
            {error && <p className={styles.error}>{error}</p>}
            {success && <p className={styles.success}>{success}</p>}
        </div>
    )
}

export default AddNewUser
