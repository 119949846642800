import { useDispatch, useSelector } from 'react-redux'
import { setFileData } from '../../../../slices/FolderTreeDataStateSlice'
import { secureDownloadFile } from '../PDFViewer/secureDownload'
import { downloadAnonymizations } from '../PDFViewer/downloadAnonymizations'

const useHandleItemClick = (setSelectedFileInfo, setIsLoading, userId, setCurrentPage) => {
  const dispatch = useDispatch()
  const fileData = useSelector(state => state.selectedFileSlice)
  const PDF_EXTENSION = '.pdf'

  return async (itemData) => {
    setIsLoading(true)
    setCurrentPage(1)
    if (itemData?.type === 'file' && itemData?.text.endsWith(PDF_EXTENSION)) {
      const fileDispatched = fileData[itemData.id]
      if (!fileDispatched) {
        const url = await secureDownloadFile(userId, itemData.id)
        let anonymizations = {}
        const nersDict = {}
        if (itemData.anonymization_mistral_processor_entities_blob_path || itemData.anonymization_verdict_processor_entities_blob_path) {
          const processorType = itemData.anonymization_mistral_processor_entities_blob_path ? 'mistral' : 'verdict'
          anonymizations = await downloadAnonymizations(userId, itemData.id, processorType)
        }
        let ners = []
        if (anonymizations.results_json?.ners) {
          ners = anonymizations.results_json.ners
          nersDict.ners = Object.values(ners)
        }
        // console.log('anonymizations', anonymizations?.ners ? Object.values(anonymizations.ners) : [])
        // Prepare the comprehensive dataset
        const fileInfo = {
          ...itemData, // Include all existing itemData fields
          url, // Add the file URL
          mapping: nersDict, // Add the anonymization mapping
          // Spread additional fields if they exist
          language: anonymizations?.results_json?.language || 'de',
          tags: anonymizations?.results_json?.tags || [],
          category: anonymizations?.results_json?.document_category || 'other',
          busySince: itemData.busySince
        }

        // Dispatch the comprehensive dataset to update the Redux state
        // console.log('fileInfo', fileInfo)
        dispatch(setFileData(fileInfo))
        // Update the selected file info with the comprehensive dataset
        setSelectedFileInfo(fileInfo)
      } else {
        // If file data is already fetched, just set the selected file info from the Redux state
        setSelectedFileInfo(fileDispatched)
      }
    } else {
      setSelectedFileInfo(null)
    }
    setIsLoading(false)
  }
}

export default useHandleItemClick
