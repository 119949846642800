import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Home from './pages'
import SignIn from './firebase/SignIn'
import Imprint from './pages/imprint'
import Privacy from './pages/privacy'

import PlaygroundLanding from './components/PlaygroundLanding/PlaygroundLanding'
import AnonymizationPWA from './pages/Playground/AnonymizationPWA/anonymizationPWA'
import VerdictSearchChat from './pages/Playground/VerdictSearchChat/VerdictSearchChat'
import PseudoLab from './pages/Playground/Pseudolab/Pseudolab'

import AddNewUser from './pages/admin/AddNewUser/AddNewUser'
import TokenRefreshComponent from './firebase/TokenRefreshComponent'

import './styles/app.scss'

export default function App () {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <TokenRefreshComponent />
                <Header />
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/playground' element={<PlaygroundLanding />} />
                    <Route path='/playground/anonymizationPWA' element={<AnonymizationPWA />} />
                    <Route path='/playground/verdictSearch' element={<VerdictSearchChat />} />
                    <Route path='/playground/pseudolab' element={<PseudoLab />} />
                    <Route path='/signin' element={<SignIn />} />
                    <Route path='/impressum' element={<Imprint />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/admin/addNewUser' element={<AddNewUser />} />
                </Routes>
                <Footer />
            </PersistGate>
        </Provider>
    )
}
