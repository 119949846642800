// MobileFilterButton.js

import React from 'react'
import { IconButton, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'
import FilterPanel from './FilterPanel'
import PropTypes from 'prop-types'

const MobileFilterButton = ({ isFilterVisible, handleFilterToggle, filters }) => {
  return (
        <>
            <IconButton
                className={`filter-toggle-btn ${isFilterVisible ? 'filter-btn-active' : ''}`}
                onClick={handleFilterToggle}
            >
                {isFilterVisible ? <CloseIcon /> : <FilterListIcon />}
            </IconButton>
            <Dialog
                open={isFilterVisible}
                onClose={handleFilterToggle}
                aria-labelledby="filter-dialog-title"
                fullScreen
            >
                <DialogContent>
                    {/* Pass filters to FilterPanel */}
                    <FilterPanel filters={filters} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFilterToggle} color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
  )
}

// Add filters to PropTypes validation
MobileFilterButton.propTypes = {
  isFilterVisible: PropTypes.bool.isRequired,
  handleFilterToggle: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired // Add this line
}

export default MobileFilterButton
