// Assuming your fake data is stored in a file called FakeResponse.json in the same directory
import fakeData from './fakeResponse.json'

/**
 * Simulated fetch request function
 * @param {string} query - The search query.
 * @param {Array} filters - The selected filters.
 * @returns {Promise} - The promise resolving to fake data.
 */
const fetchFakeData = async (query, filters) => {
  // Simulate the delay of a real API call
  await new Promise(resolve => setTimeout(resolve, 1000))

  // In a real application, you would use query and filters to modify your fetch request
  // For example: const response = await fetch(`your-api-endpoint?query=${query}&filters=${JSON.stringify(filters)}`);
  // const data = await response.json();

  // For the purpose of this simulation, we'll just return the fake data
  // Here you can also add logic to filter or alter fakeData based on the query and filters
  return fakeData
}

export default fetchFakeData
