import React from 'react'
import styles from './Team.module.scss'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FotoShahrezaye from '../../assets/images/team/shahrezaye.jpg'
import FotoBalk from '../../assets/images/team/balk.jpg'
import FotoTunk from '../../assets/images/team/tunk.jpg'

export default function Team () {
    return (
        <section className={styles.team} id='ueber-uns'>
            <h2>Das Team hinter ElephantLabs.</h2>
            <p>Elephant Labs ist eine Ausgründung von Wissenschaftlern der Technischen Universität München, Universität St.Gallen und der Universität Zürich mit Sitz im Herzen Bayerns. Gemeinsames Ziel ist die Entwicklung von KI-Lösungen basierend auf state-of-the-art Technologien, um die Herausforderungen in der Rechtspraxis zu bewältigen. Unser Grundsatz ist der verantwortungsvolle Einsatz von künstlicher Intelligenz auf Basis von Explainable AI (XAI). Dabei steht der Mensch im Mittelpunkt.</p>
            <div>
                <img src={FotoShahrezaye} alt='Portrait-Foto von Dr. Morteza Shahrezaye'/>
                <h4>Dr. Morteza Shahrezaye<br/>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                        Founder<a href='https://www.linkedin.com/in/morteza-shahrezaye/'><LinkedInIcon fontSize="small"/></a>
                    </div>
                </h4>
                <a href='mailto:m.shahrezaye@elephantlabs.ai'>m.shahrezaye@elephantlabs.ai</a>
                <br/>
            </div>
            <div>
                <img src={FotoBalk} alt='Portrait-Foto von Matthias Balk'/>
                <h4>Matthias Balk<br/>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                        Co-Founder<a href='https://www.linkedin.com/in/matthiasbalk/'><LinkedInIcon fontSize="small"/></a>
                    </div>
                </h4>
                <a href='mailto:m.balk@elephantlabs.ai'>m.balk@elephantlabs.ai</a>
            </div>
            <div>
            <img src={FotoTunk} alt='Portrait-Foto von Tilman Tunk'/>
                <h4>Tilman Tunk<br/>
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                        Co-Founder<a href='https://www.linkedin.com/in/tilman-tunk/'><LinkedInIcon fontSize="small"/></a>
                    </div>
                </h4>
                <a href='mailto:t.tunk@elephantlabs.ai'>t.tunk@elephantlabs.ai</a>
            </div>
        </section>
    )
}
