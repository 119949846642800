import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import fetchClusters from '../FetchRequests/fetchClusters'
import { useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
function FilterPanel ({ loading, setLoading, filters, graphData, results, setResults, setFilters, combinedQuery, appliedFilters, selectedFilters, setAppliedFilters, setSelectedFilters }) {
  const userIdToken = useSelector((state) => state.auth.userIdToken)
  const [expandedCategories, setExpandedCategories] = useState({})

  const filterKeys = ['topic_title', 'court_meta_de', 'year', 'language']
  const filterKeysMapping = {
    topic_title: 'Thementitel',
    court_meta_de: 'Gericht',
    year: 'Jahr',
    language: 'Sprache'
}
  // generate a list of unique values for each filter key by iterating through the filters
  const filterValues = filterKeys.reduce((acc, key) => {
    // Group values and count their occurrences
    const valueCounts = filters.reduce((countAcc, filter) => {
      const value = filter[key]
      if (countAcc[value]) {
        countAcc[value]++
      } else {
        countAcc[value] = 1
      }
      return countAcc
    }, {})

    // Transform the counts object into an array of { value, count } objects
    // and then sort this array by count in descending order
    acc[key] = Object.entries(valueCounts)
        .map(([value, count]) => ({ value, count }))
        .sort((a, b) => b.count - a.count) // Sort by count in descending order

    return acc
  }, {})
  // console.log('filter_values', filterValues)
  const handleCheckboxChange = (category, value) => {
    setSelectedFilters(prevSelectedFilters => {
      const isSelected = prevSelectedFilters[category].includes(value)
      if (isSelected) {
        // Filter out the value if it's already selected
        return {
          ...prevSelectedFilters,
          [category]: prevSelectedFilters[category].filter(selectedValue => selectedValue !== value)
        }
      } else {
        // Add the value if it's not already selected
        return {
          ...prevSelectedFilters,
          [category]: [...prevSelectedFilters[category], value]
        }
      }
    })
  }

  const toggleCategory = (category) => {
    setExpandedCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }))
  }

  const onApplyFilters = async () => {
    try {
      // set loading to true
      setLoading(true)
      const clusters = await fetchClusters(results, combinedQuery, userIdToken, filters, selectedFilters)
      setFilters(clusters.search_results_clustered)
      setResults(clusters.search_results)
      setAppliedFilters({ ...selectedFilters }) // Update applied filters here
      setLoading(false)
    } catch (error) {
      console.error('Error fetching clusters: ', error)
      setLoading(false)
    }
  }

  return (
      <div className="filter-graph-section">
        {/* Filters Section */}
        <div className="active-filters-section">
          <div className="active-filters">
            {/* if any of the filters are selected but not applied then show a text: "click on the apply button" */}
            {JSON.stringify(selectedFilters) !== JSON.stringify(appliedFilters) && (
                <div className="filters-notification">
                  Klicken Sie auf den &quot;Filter anwenden&quot; Button, um die Änderungen anzuwenden.
                </div>
            )}
            {Object.keys(selectedFilters).flatMap(category =>
                selectedFilters[category].map(value => (
                    <div
                        key={`${category}-${value}`}
                        className={`active-filter ${appliedFilters[category].includes(value) ? 'applied-filter' : 'pending-filter'}`}
                        title={`${appliedFilters[category].includes(value) ? 'Angewendet: ' : 'Nicht angewendet: '}${filterKeysMapping[category]}: ${value}`}
                    >
                      {filterKeysMapping[category]}: {value}
                      <button onClick={() => handleCheckboxChange(category, value)} className="remove-filter">
                        &times;
                      </button>
                    </div>
                ))
            )}
          </div>
        </div>
        <div className="filter-section">
          <h3 style={{ textAlign: 'center' }}>Filter</h3>
          {loading && (
              <div className="filters-overlay">
                <CircularProgress/>
              </div>
          )}
          <div className="filter-container">
            {
              Object.keys(filterValues).map((filterCategory) => (
                  <div key={filterCategory} className={`filter-category ${filterCategory}`}>
                    <h4 className="clickable-header" onClick={() => toggleCategory(filterCategory)}>
                      {filterKeysMapping[filterCategory]}
                      {expandedCategories[filterCategory] ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </h4>
                    {expandedCategories[filterCategory] && (
                        <div className={filterCategory === 'year' ? 'filter-options-inline' : 'filter-options'}>
                          {filterValues[filterCategory].map(({ value, count }, index) => (
                              <label key={index} className={`filter-option ${filterCategory}-option`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                      type="checkbox"
                                      checked={selectedFilters[filterCategory].includes(value)}
                                      onChange={() => handleCheckboxChange(filterCategory, value)}
                                  />
                                  {value}
                                </span>
                                <span>{`(${count})`}</span>
                              </label>
                          ))}
                        </div>
                    )}
                  </div>
              ))
            }
          </div>
        </div>
        <div className="filters-button-container">
          <button
              className={`search-button ${loading || JSON.stringify(selectedFilters) === JSON.stringify(appliedFilters) ? 'disabled' : 'action'}`}
              onClick={onApplyFilters}
          >
            Filter anwenden
          </button>
        </div>
      </div>
  )
}

FilterPanel.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  filters: PropTypes.any,
  graphData: PropTypes.shape({
    nodes: PropTypes.array,
    edges: PropTypes.array
  }),
  results: PropTypes.array,
  setResults: PropTypes.func,
  setFilters: PropTypes.func,
  combinedQuery: PropTypes.string,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  appliedFilters: PropTypes.object,
  setAppliedFilters: PropTypes.func
}
export default React.memo(FilterPanel)
