import SecureFileStatusManager from '../Logics/SecureFileStatusManager' // Update this path based on actual location
import anonymizeVerdict from '../ServerLogics/anonymizeVerdict' // Update this path
import { auth } from '../../../../firebase/firebase'
import { useNavigate, useLocation } from 'react-router-dom'

export const handlePromptAnonymizationSubmit = async ({
                                            userId,
                                            selectedFileInfo,
                                            userIdToken,
                                            setIsLoading,
                                            setRefinementDialogOpen,
                                            setIsAnonymizationErrorDialogOpen,
                                            setTimeToRetry,
                                            setNumberPages,
                                            setRateLimitDialogOpen,
                                            closeAnonymizationDialog,
                                            userQuery
                                        }) => {
    setIsLoading(true)
    setRefinementDialogOpen(false)
    let errorReceived = { message: '' }

    try {
        await SecureFileStatusManager.setFileBusy(userId, selectedFileInfo.id)
        setIsLoading(false)
        await anonymizeVerdict(selectedFileInfo.id, userId, userIdToken, 'mistral_processor', userQuery)
    } catch (error) {
        errorReceived = error
        if (error.message === 'FileBusyError') {
            setIsAnonymizationErrorDialogOpen(true)
            return // Exit the function or handle accordingly
        }

        await SecureFileStatusManager.clearFileBusy(userId, selectedFileInfo.id)
        console.error('Error:', error)
        if (error.message === 'RateLimitError') {
            setTimeToRetry(error.timeToWait)
            setNumberPages(error.numberPages)
            setRateLimitDialogOpen(true) // Open the dialog for 429 errors
        } else if (error.message === 'UnauthorizedError') {
            const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
            if (confirm) {
                await auth.signOut()
                const navigate = useNavigate()
                const location = useLocation()
                navigate('/signin', { state: { from: location.pathname } })
            }
        }
    } finally {
        if (errorReceived.message !== 'FileBusyError') {
            await SecureFileStatusManager.clearFileBusy(userId, selectedFileInfo.id)
        }
        setIsLoading(false)
        closeAnonymizationDialog()
    }
}
