import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Popover, MenuItem, List, Divider, Typography, Tooltip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setUserIdScreenName } from '../../slices/authSlice'
import { auth } from '../../firebase/firebase'
import styles from './Avatar.module.scss'

export default function Avatar () {
  const user = useSelector(state => state.auth.userId)
  const userScreenName = useSelector(state => state.auth.userScreenName)
  const dispatch = useDispatch()
  const userRef = useRef(null)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(true)

  const logout = async () => {
    try {
      await auth.signOut()
    } catch (error) {
      // Handle any logout errors
    }
  }

  const handleLogout = async () => {
    await logout()
    dispatch(setUserIdScreenName({ userId: null, userScreenName: null, userIdToken: null }))
    navigate('/')
  }
  const handlePopupOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopupClose = () => {
    setTimeout(() => {
      setAnchorEl(null)
    }, 0)
  }

  const handleLogoutClick = () => {
    handleLogout()
    handlePopupClose()
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (userRef.current && !userRef.current.contains(event.target)) {
        handlePopupClose()
      }
    }

    window?.addEventListener('mousedown', handleOutsideClick)

    return () => {
      window?.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const avatarStyle = user ? {} : { backgroundColor: 'hsla(0, 0%, 56%, 0.4)' }

  return (
      <div className={styles.avatar} style={avatarStyle}>
        {userScreenName === undefined ? (
            <span>Loading...</span>
        ) : userScreenName ? (
            <div
                ref={userRef}
                onClick={handlePopupOpen}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0px 10px',
                  marginLeft: '0px',
                  zIndex: 122222
                }}
            >
              <span>{userScreenName?.charAt(0).toUpperCase()}</span>
              <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handlePopupClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  PaperProps={{
                    style: {
                      borderRadius: 12,
                      boxShadow: '1px 1px 5px 2px rgba(0, 0, 0, 0.2)',
                      padding: 0
                    }
                  }}
              >
                <List style={{ textAlign: 'center' }}>
                  {/* <span>{user?.displayName?.charAt(0).toUpperCase()}</span> */}
                  {/* <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: 0 }}> */}
                  {/*  {user?.displayName} */}
                  {/* </Typography> */}
                  {/* <Divider style={{ marginBottom: '10px' }}/> */}
                  {/* <MenuItem onClick={handlePopupClose}> */}
                  {/*  Profil */}
                  {/* </MenuItem> */}
                  {/* <MenuItem onClick={handlePopupClose}> */}
                  {/*  Einstellungen */}
                  {/* </MenuItem> */}
                  <MenuItem>
                    {userScreenName}
                  </MenuItem>
                  <MenuItem onClick={handleLogoutClick} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    Abmelden
                  </MenuItem>
                </List>
              </Popover>
            </div>

        ) : (
            <Link to='/signin'>
              <Tooltip
            title='Log in'
            componentsProps={{
              tooltip: {
                sx: {
                  padding: '4px 6px',
                  color: 'hsla(0, 0%, 98%, 1)',
                  backgroundColor: 'hsla(0, 0%, 56%, 1)',
                  borderRadius: '2px',
                  fontFamily: 'ABC Diatype Regular Web',
                  fontSize: '12px',
                  lineHeight: '14px'
                }
              }
            }}
          >
            <svg aria-label='User Account' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'>
              <rect x='10.5' y='4.5' width='7' height='7' rx='1.5' />
              <path d='M21.5 28V19.3284C21.5 18.798 21.2893 18.2893 20.9142 17.9142L19.0858 16.0858C18.7107 15.7107 18.202 15.5 17.6716 15.5H10.3284C9.79799 15.5 9.28929 15.7107 8.91421 16.0858L7.08579 17.9142C6.71071 18.2893 6.5 18.798 6.5 19.3284V28' />
            </svg>
          </Tooltip>
        </Link>
      )}
    </div>
  )
}
