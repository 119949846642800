import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { auth, db } from '../firebase/firebase'
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import { updateFileData } from '../slices/FolderTreeDataStateSlice'
import { secureDownloadFile } from '../pages/Playground/AnonymizationPWA/PDFViewer/secureDownload'
import { downloadAnonymizations } from '../pages/Playground/AnonymizationPWA/PDFViewer/downloadAnonymizations'

function useFetchFolderTreeData () {
  const dispatch = useDispatch()
  const [folderTreeData, setFolderTreeData] = useState([])
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const userId = useSelector((state) => state.auth.userId)

  const fetchAndDispatchFileData = async (userId, fileDoc, dispatch) => {
    const fileId = fileDoc.id
    const fileDataSnapshot = fileDoc.data()

    // Convert Firestore timestamp to a more usable format if necessary, e.g., busySince
    // Assume that busySince is the only timestamp in fileDataSnapshot you need to convert
    const busySince = fileDataSnapshot.busySince ? fileDataSnapshot.busySince.seconds : null

    // Construct the new file data object from the Firestore document.
    // Omit fields that should not be directly copied or need transformation, like timestamps.
    const newData = {
      ...fileDataSnapshot,
      busySince // Use the converted timestamp
      // Any other necessary transformations or omissions
    }

    let anonymizations = {}
    const nersDict = {}
    if (newData.anonymization_mistral_processor_entities_blob_path || newData.anonymization_verdict_processor_entities_blob_path) {
      const processorType = newData.anonymization_mistral_processor_entities_blob_path ? 'mistral' : 'verdict'
      anonymizations = await downloadAnonymizations(userId, newData.id, processorType)
    }
    let ners = []
    if (anonymizations.results_json?.ners) {
      ners = anonymizations.results_json.ners
      nersDict.ners = Object.values(ners)
    }
    newData.mapping = nersDict
    newData.userQuery = newData.userQuery || 'Anonymisiere das Dokument gemäß DSGVO / GDPR'

    delete newData.cryptoKey
    delete newData.iv
    delete newData.created_at
    // console.log('newDatanewDatanewDatanewData', newData)

    // Dispatch an action to update the file data in the Redux store.
    // The reducer will take care of preserving the `url` and `mapping` fields.
    dispatch(updateFileData({ fileId, newData }))
  }

  // Function to handle real-time file updates
  const handleFileUpdate = (change) => {
    // console.log('change', change)
    setFolderTreeData(currentTreeData => {
      const newData = [...currentTreeData] // Create a shallow copy of the current state
      // Implement logic to find and update the specific file data in newData based on the change.doc.id
      const fileIndex = newData.findIndex(file => file.id === change.doc.id)
      if (fileIndex !== -1) {
        const updatedFile = newData[fileIndex]
        // Update fields based on change.doc.data()
        updatedFile.busy = change.doc.data().busySince
        // Replace the old file data with the updated one
        newData[fileIndex] = updatedFile
      }
      // console.log('newData', newData)
      // dispatch the new file data here
      fetchAndDispatchFileData(userId, change.doc, dispatch)
      return newData
    })
  }

  // Function to initialize real-time listeners for file documents
  const subscribeToFileChanges = (userId) => {
    const fileQuery = query(collection(db, 'files'), where('user_id', '==', userId))
    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          handleFileUpdate(change)
        }
      })
    })
    return unsubscribe
  }

  useEffect(() => {
    let unsubscribeFiles = null

    if (authenticated && userId) {
      // Initialize real-time subscriptions
      unsubscribeFiles = subscribeToFileChanges(userId, setFolderTreeData)
    }

    return () => {
      // Clean up subscriptions
      if (unsubscribeFiles) {
        unsubscribeFiles()
      }
    }
  }, [authenticated, userId])

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setAuthenticated(!!user)
    })

    return () => unsubscribeAuth()
  }, [])

  const fetchNewTreeData = async (userId) => {
    try {
      const folders = await getDocs(query(collection(db, 'folders'), where('user_id', '==', userId)))
      const files = await getDocs(query(collection(db, 'files'), where('user_id', '==', userId)))
      return buildTree(null, folders, files)
    } catch (error) {
      console.error('Error fetching new folder tree data:', error)
      return []
    }
  }

  const updateExpandedState = (newTree, oldTree) => {
    return newTree.map(newItem => {
      const oldItem = oldTree.find(item => item.id === newItem.id)
      if (oldItem) {
        newItem.expanded = oldItem.expanded
        if (newItem.items && newItem.items.length > 0) {
          newItem.items = updateExpandedState(newItem.items, oldItem.items || [])
        }
      }
      return newItem
    })
  }

  const refreshFolderTreeData = async (currentTreeData) => {
    const newTreeData = await fetchNewTreeData(userId)
    if (!currentTreeData) {
      setFolderTreeData(newTreeData)
      return
    }
    const updatedTreeData = updateExpandedState(newTreeData, currentTreeData)
    setFolderTreeData(updatedTreeData)
  }

  const fetchFolderTreeData = async (userId) => {
    try {
      const folders = await getDocs(query(collection(db, 'folders'), where('user_id', '==', userId)))
      const files = await getDocs(query(collection(db, 'files'), where('user_id', '==', userId)))
      const treeData = buildTree(null, folders, files)
      setFolderTreeData(treeData)
      setLoading(false)
      // console.log('Folder tree data fetched')
    } catch (error) {
      console.error('Error fetching folder tree data:', error)
    }
  }

  const buildTree = (parentId, folders, files) => {
    const children = []
    folders.docs.forEach((folder) => {
      if (folder.data().parent_id === parentId) {
        const folderData = {
          id: folder.id,
          text: folder.data().name,
          type: 'folder',
          expanded: false,
          icon: 'folder-icon',
          items: buildTree(folder.id, folders, files)
        }
        children.push(folderData)
      }
    })

    files.docs.forEach((file) => {
      const fileSnapshot = file.data()
      // drop cryptoKey and iv from the file data if present
      delete fileSnapshot.cryptoKey
      delete fileSnapshot.iv
      delete fileSnapshot.created_at
      // Convert Firestore timestamp to epoch seconds for busySince, if present
      const busySinceEpochSeconds = fileSnapshot.busySince ? fileSnapshot.busySince.seconds : null
      // Determine anonymization type based on available paths
      const anonymizationType = fileSnapshot.anonymization_mistral_processor_entities_blob_path ? 'mistral_processor' : 'verdict_processor'
      if (fileSnapshot.parent_id === parentId) {
        // Initialize fileData with all fields from the document snapshot
        const fileData = {
          ...fileSnapshot,
          id: file.id, // Ensure the document ID is included
          type: 'file', // Specify the type explicitly
          icon: 'file-icon', // Set the icon explicitly
          text: fileSnapshot.name, // Use the name field as the text
          anonymization_type: anonymizationType, // Include derived anonymization type
          busySince: busySinceEpochSeconds // Use the converted busySince
        }
        // Optionally, override or set defaults for any missing but required fields
        fileData.userQuery = fileData.userQuery || 'Anonymisiere das Dokument gemäß DSGVO / GDPR'
        children.push(fileData)
      }
    })
    return children
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (authenticated && userId) {
      fetchFolderTreeData(userId)
    }
  }, [authenticated, userId])
  // console.log('folderTreeData', folderTreeData)
  return { folderTreeData, loading, refreshFolderTreeData }
}

export default useFetchFolderTreeData
