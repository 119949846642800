import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { Container, Typography, CircularProgress, Icon } from '@material-ui/core'
import 'devextreme/dist/css/dx.light.css'
import TreeView from 'devextreme-react/tree-view'
import TreeItemsContextMenu from './TreeItemsContextMenu/TreeItemsContextMenu'
import '../../../../styles/folderTreeData.scss'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import LockIcon from '@mui/icons-material/EnhancedEncryption'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

function FolderTreeData ({ onItemClick, onFileDelete, folderTreeData, loading, refreshFolderTreeData }) {
  const treeViewRef = useRef(null)
  const [contextMenuPosition, setContextMenuPosition] = useState(null)
  const [parentId, setParentId] = useState(null) // To hold the ID of the right-clicked folder
  const [itemType, setItemType] = useState(null) // To hold the type of the right-clicked item (file or folder)
  const handleItemClick = (e) => {
    onItemClick(e.itemData)
  }

  // Handling context menu open
  const treeViewItemContextMenu = (e) => {
    e.event.preventDefault() // Prevent the default right-click context menu
    const selectedFolderId = e.itemData.id // Get the ID from the clicked node
    const selectedItemType = e.itemData.type // Get the type from the clicked node
    setParentId(selectedFolderId) // Set the right-clicked folder's ID
    setItemType(selectedItemType) // Set the right-clicked item's type

    setContextMenuPosition({
      left: e.event.clientX,
      top: e.event.clientY
    })
  }

  // This will handle the double click event
  // const handleItemDoubleClick = (e) => {
  //   const itemId = e.target.closest('.dx-treeview-item')?.getAttribute('id')
  //   const itemType = e.target.closest('.dx-treeview-item')?.getAttribute('type')
  //   console.log('Double clicked on itemId', itemId)
  //   if (itemId) {
  //     console.log(folderTreeData)
  //     // Get the corresponding item from folderTreeData using the ID
  //     const item = folderTreeData.find(item => item.id === itemId)
  //     console.log('Double clicked on items', item)
  //     if (item) {
  //       // Perform the action you want on double click
  //       console.log('Double clicked on file', item)
  //     }
  //   }
  // }
  // useEffect(() => {
  //   const treeViewElement = treeViewRef.current?.instance?.$element().get(0)
  //   if (treeViewElement) {
  //     treeViewElement.addEventListener('dblclick', handleItemDoubleClick)
  //   }
  //
  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     if (treeViewElement) {
  //       treeViewElement.removeEventListener('dblclick', handleItemDoubleClick)
  //     }
  //   }
  // }, [folderTreeData, onItemClick]) // Re-run when these dependencies change

  const itemRender = (item) => {
    let IconComponent
    const isRootFolder = item.id === 'root' // Assuming 'root' is the ID of your root folder
    if (item.type === 'folder') {
      IconComponent = (<><FolderOutlinedIcon fontSize="small" /><LockIcon fontSize="small" style={{ marginLeft: '4px' }} /></>)
    } else {
      IconComponent = (<><PictureAsPdfOutlinedIcon fontSize="small" /><LockIcon fontSize="small" style={{ marginLeft: '4px' }} /></>)
    }

    const textStyle = { position: 'relative', top: '2px', marginLeft: '8px' } // Adjust value as needed

    const containerStyle = {
      display: 'flex',
      alignItems: 'center' // Vertically aligns the children in the middle
    }

    return (
        <div style={containerStyle}>
          {IconComponent}
          <span style={textStyle}>{item.text}</span>
        </div>
    )
  }

  const onItemRendered = (e) => {
    e.itemElement.setAttribute('id', e.itemData.id)
    e.itemElement.setAttribute('type', e.itemData.type)
  }

  return (
      <Container className="tree-folder-container" maxWidth={false}>
        {loading ? (
            <CircularProgress />
        ) : (
            <div className="form" style={{ position: 'relative' }}>
              {folderTreeData[0].items.length < 1 && (
                  <div className="overlay-info">
                    <Typography variant="h6">Keine Dateien vorhanden</Typography>
                    <Typography variant="body1" style={{ marginTop: '1rem' }}>
                      Per Rechtsklick auf den Root-Ordner können Sie neue Ordner erstellen, PDF-Dateien hochladen und
                      Ihre Dateien sicher verwalten.
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: '0.8rem' }}>
                      🔒 Alle Datenübertragungen sind verschlüsselt.
                    </Typography>
                  </div>
              )}
              <TreeView
                  ref={treeViewRef}
                  id="treeview"
                  items={folderTreeData}
                  className="tree-view"
                  onItemContextMenu={treeViewItemContextMenu}
                  itemRender={itemRender}
                  onItemRendered={onItemRendered}
                  onItemClick={handleItemClick}
              />
              {folderTreeData[0].items.length >= 1 && (
                  <div className="tree-help-icon">
                    <Tooltip
                        title="Per Rechtsklick auf den Root-Ordner können Sie neue Ordner erstellen, PDF-Dateien hochladen und
                      Ihre Dateien sicher verwalten."
                        placement="top">
                      <IconButton size="small">
                        <HelpOutlineIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
              )}
            </div>
        )}
        <TreeItemsContextMenu
            position={contextMenuPosition}
            setContextMenu={setContextMenuPosition}
            id={parentId}
            type={itemType}
            refreshFolderTree={() => refreshFolderTreeData(folderTreeData)}
            onFileDelete={onFileDelete}
        />
      </Container>
  )
}

FolderTreeData.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  folderTreeData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  refreshFolderTreeData: PropTypes.func.isRequired
}
export default FolderTreeData
