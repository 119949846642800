import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './ContactForm.module.scss'
import SubmitButton from './SubmitButton'

export default function ContactForm () {
  // State to hold form field values
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    organisation: '',
    text: ''
  })

  // Update state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  // Check if all fields are filled and email is valid
  const isFormComplete = formValues.name && isValidEmail(formValues.email) && formValues.organisation

  // Reset form values
  const resetForm = () => {
    setFormValues({
      name: '',
      email: '',
      organisation: '',
      text: ''
    })
  }

  return (
    <section className={styles.contact} id='kontakt'>
      <div>
        <h2>Kontakt aufnehmen und Zeit sparen.</h2>
        <p>Wir helfen Ihnen weiter.</p>
      </div>
      <div className={styles.form}>
        <p>Unser Team führt Sie gerne durch die wichtigsten Features und macht Sie mit allen Möglichkeiten und
          Produktvorteilen vertraut.</p>
        <input
            type='text'
            name='name'
            value={formValues.name}
            onChange={handleInputChange}
            placeholder='Name'
            aria-label='Name'
            spellCheck={false}
            autoCorrect='off'
            autoComplete='off'
        />
        <input
            type='email'
            name='email'
            value={formValues.email}
            onChange={handleInputChange}
            placeholder='E-Mail'
            aria-label='E-Mail'
            spellCheck={false}
            autoCorrect='off'
            autoComplete='off'
        />
        <input
            type='text'
            name='organisation'
            value={formValues.organisation}
            onChange={handleInputChange}
            placeholder='Organisation'
            aria-label='Organisation'
            spellCheck={false}
            autoCorrect='off'
            autoComplete='off'
        />
        <textarea
            name='text'
            value={formValues.text}
            onChange={handleInputChange}
            placeholder='Ihre Nachricht'
            aria-label='Text'
            spellCheck={true}
            autoCorrect='off'
            autoComplete='off'
            rows={5}
            className={styles.textArea}
        />
        <p>Durch die Weitergabe Ihrer Daten erklären Sie sich damit einverstanden, E-Mails von uns zu erhalten und
          akzeptieren die <Link to={'privacy'}>Datenschutzrichtlinie</Link>.</p>
        <SubmitButton
            formData={formValues}
            disabled={!isFormComplete}
            onSuccess={resetForm}
        />
      </div>
    </section>
  )
}
