import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@material-ui/core'
import { deleteDoc, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage'
import { db, storage } from '../../../../../../firebase/firebase'
import Button from '../../../../../../components/Button/Button'

function useDeleteFolderLogic (userId, folderId, refreshFolderTree, setContextMenu) {
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false)
  const [folderToDelete, setFolderToDelete] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletedFilesCount, setDeletedFilesCount] = useState(0)
  const [folderName, setFolderName] = useState('') // State variable to store folder name

  const handleDeleteFolderClick = async () => {
    setContextMenu(null)
    setDeleteFolderDialogOpen(true)
    setFolderToDelete(folderId)

    // Fetch the folder document to get the name
    const folderDocumentRef = doc(db, 'folders', folderId)
    const folderDocumentSnapshot = await getDoc(folderDocumentRef)
    if (folderDocumentSnapshot.exists()) {
      setFolderName(folderDocumentSnapshot.data().name)
    } else {
      console.error('Folder not found')
      setFolderName('')
    }
  }

  const deleteFilesInFolder = async (folderId) => {
    // Query to get files in the folder
    const filesQuery = query(collection(db, 'files'), where('parent_id', '==', folderId))
    const querySnapshot = await getDocs(filesQuery)

    let count = 0
    // Iterate over each file and delete it
    for (const docSnapshot of querySnapshot.docs) {
      const fileData = docSnapshot.data()
      // Delete file from storage
      if (fileData.name) {
        const fileRef = ref(storage, `userFiles/${userId}/${folderId}/${fileData.name}`)
        await deleteObject(fileRef)
      }
      if (fileData.paragraphs_blob_path) {
        const paragraphsBlobRef = ref(storage, fileData.paragraphs_blob_path)
        await deleteObject(paragraphsBlobRef)
      }
      if (fileData.presidio_entities_blob_path) {
        const entitiesBlobRef = ref(storage, fileData.entities_blob_path)
        await deleteObject(entitiesBlobRef)
      }
      // Delete file document from Firestore
      await deleteDoc(doc(db, 'files', docSnapshot.id))
      count++
      setDeletedFilesCount(prev => prev + 1)
    }
  }

  const deleteFolder = async (folderId) => {
    // Recursively delete subfolders and their files
    setIsDeleting(true) // Start deletion
    setDeletedFilesCount(0) // Reset deleted files count

    const subfoldersQuery = query(collection(db, 'folders'), where('parent_id', '==', folderId))
    const subfolderSnapshots = await getDocs(subfoldersQuery)
    for (const subfolder of subfolderSnapshots.docs) {
      await deleteFolder(subfolder.id)
    }

    // Delete files in the current folder
    await deleteFilesInFolder(folderId)

    // Delete the folder document itself
    await deleteDoc(doc(db, 'folders', folderId))

    setIsDeleting(false) // End deletion
  }

  const confirmDeleteFolder = async () => {
    if (!folderToDelete) return

    // get the folder document
    const folderDocumentRef = doc(db, 'folders', folderToDelete)
    const folderDocumentSnapshot = await getDoc(folderDocumentRef)
    if (folderDocumentSnapshot.data().name === 'Root') {
      setDeleteFolderDialogOpen(false)
      setErrorMessage('"Root" Ordner kann nicht gelöscht werden.')
      setDeleteFolderDialogOpen(true)
      return
    }
    try {
      await deleteFolder(folderToDelete)
      refreshFolderTree()
    } catch (error) {
      console.error('Error deleting folder:', error)
    } finally {
      setDeleteFolderDialogOpen(false)
      setFolderToDelete(null)
    }
  }

  const DeleteFolderDialog = (
        <Dialog
            open={deleteFolderDialogOpen}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setDeleteFolderDialogOpen(false)
                setErrorMessage('')
                setIsDeleting(false)
              }
            }}
        >
            <DialogTitle>Ordner löschen</DialogTitle>
            <DialogContent>
                <Typography color="error">{errorMessage}</Typography>
                {isDeleting && (
                    <>
                        <CircularProgress />
                        <Typography variant="body2" style={{ marginTop: 10 }}>
                            Anzahl gelöschter Dateien: {deletedFilesCount}
                        </Typography>
                    </>
                )}
                <p>Sind Sie sicher, dass Sie den Ordner &quot;{folderName}&quot; löschen wollen?</p> {/* Include the folder name */}
            </DialogContent>
            <DialogActions>
                <Button
                    className="preview-btn action"
                    onClick={() => { setDeleteFolderDialogOpen(false); setErrorMessage(''); setIsDeleting(false) }}
                    color="primary"
                    disabled={isDeleting}
                    label="Abbrechen"
                    isCTA={!isDeleting}
                />
                <Button
                    className="preview-btn action"
                    onClick={confirmDeleteFolder}
                    color="secondary"
                    disabled={isDeleting}
                    label="Löschen"
                    isCTA={!isDeleting}
                />
            </DialogActions>
        </Dialog>
  )

  return { handleDeleteFolderClick, DeleteFolderDialog }
}

export default useDeleteFolderLogic
