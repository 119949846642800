import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, LinearProgress, CircularProgress, Switch } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setPDFViewerActive } from '../../../slices/collaborativeDocumentAnonymizationStateSlice'
import anonymizeVerdict from './ServerLogics/anonymizeVerdict'
import DownloadRedactedButton from './ServerLogics/DownloadRedactedButton'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { updateAnonymizationMapping } from '../../../slices/FolderTreeDataStateSlice'
import { anonymizationImportanceMapping, anonymizationLevelsInfo, anonymizationLevelsExplanation } from './FileInfoSectionComponents/anonymizationDefinitions'
import AnonymizationOptionsDialog from './FileInfoSectionComponents/AnonymizationOptionsDialog'
import RateLimitDialog from './FileInfoSectionComponents/RateLimitDialog'
import AnonymizationErrorDialog from './FileInfoSectionComponents/AnonymizationErrorDialog'
import RefinementDialog from './FileInfoSectionComponents/RefinementDialog'
import { handlePromptAnonymizationSubmit } from './FileInfoSectionComponents/handlePromptAnonymizationSubmit'

import { auth } from '../../../firebase/firebase'
import { useNavigate } from 'react-router-dom'
import SecureFileStatusManager from './Logics/SecureFileStatusManager'

const buttonStyle = {
  marginTop: '1rem'
}
// Add CSS for smooth color transition
const dynamicProgressStyle = {
  transition: 'background-color 2s ease-in-out',
  backgroundColor: 'primary'
}
function FileInfoSection ({ selectedFileInfo }) {
  const dispatch = useDispatch()
  if (!selectedFileInfo) {
    return (
        <div className="file-info-section"
             style={{ display: 'flex' }}>
          <div className="overlay-info relative">
            <Typography variant="h6" style={{ color: 'hsla(0, 0%, 56%, 1)' }}>Keine Datei ausgewählt</Typography>
            <Typography variant="body1" style={{ color: 'hsla(0, 0%, 56%, 1)', marginTop: '1rem' }}>
              Wählen Sie eine PDF-Datei für die Anonymisierung im Bedienfeld unter dem Root-Ordner. Sie können weitere PDF-Dateien hinzufügen, indem Sie per Rechtsklick auf &quot;Datei hochladen&quot; klicken.
            </Typography>
          </div>
        </div>
    )
  }
  const navigate = useNavigate()
  const [dynamicClass, setDynamicClass] = useState('dynamic-progress-color')
  const userId = useSelector((state) => state.auth.userId)
  const selectedFileData = useSelector(state => state.selectedFileSlice[selectedFileInfo.id])
  const anonymizations = selectedFileData?.mapping
  const [userQuery, setUserQuery] = useState(selectedFileData.userQuery)
  const [refinementDialogOpen, setRefinementDialogOpen] = useState(false)
  const hasAnonymizationsData = anonymizations && anonymizations.ners
  // console.log('anonymizationsanonymizationsanonymizations', anonymizations)
  const [selectedAnonymizationLevel, setSelectedAnonymizationLevel] = useState('Stufe 2 - Erweiterte Anonymisierung')
  const [isAnonymizationDialogOpen, setIsAnonymizationDialogOpen] = useState(false)
  const userIdToken = useSelector((state) => state.auth.userIdToken)

  // Add states to manage toggle states
  const [anonymizeToggles, setAnonymizeToggles] = useState({})
  const [combinedList, setCombinedList] = useState([])
  const [initialOrder, setInitialOrder] = useState([])

  const [isRateLimitDialogOpen, setRateLimitDialogOpen] = useState(false)
  const [isAnonymizationErrorDialogOpen, setIsAnonymizationErrorDialogOpen] = useState(false)
  const [anonymizationErrorMessage, setAnonymizationErrorMessage] = useState('')
  const [timeToRetry, setTimeToRetry] = useState(0)
  const [numberPages, setNumberPages] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  // Function to handle closing the dialog
  const handleCloseRateLimitDialog = () => {
    setRateLimitDialogOpen(false)
  }

  const handleCloseAnonymizationErrorDialog = () => {
    setIsAnonymizationErrorDialogOpen(false)
  }

  // Function to handle the "Kontakt aufnehmen" action
  const handleContact = () => {
    // Redirect the user or refinementDialogOpen the contact form
    navigate('/#kontakt')
  }

  const [fileId, setFileId] = useState(selectedFileInfo ? selectedFileInfo.id : null)

  useEffect(() => {
    if (selectedFileInfo && (selectedFileInfo.id !== fileId)) {
      // console.log('resetting states')
      setFileId(selectedFileInfo.id) // Update fileId state
      setInitialOrder([]) // Reset initialOrder
      setCombinedList([]) // Reset combinedList
      setAnonymizeToggles({}) // Reset toggles
    }
  }, [selectedFileInfo, fileId, anonymizations])

  const anonymizationNecessityMapping = {
    veryhigh: 1,
    high: 2,
    medium: 3,
    low: 4,
    verylow: 5
  }
  const getItemPriority = (item) => {
    if (item.category === 'manual_addition') {
      return 0
    }
    if (item.type === 'image' || item.type === 'filled_shape') {
      return 6
    }
    if (item.alignment === 'Aligned') {
      return 1
    }
    if (item.anonymization_necessity === 'high') {
      return 2
    }
    if (item.anonymization_necessity === 'veryhigh') {
      return 1
    }
    if (item.anonymization_necessity === 'medium') {
      return 3
    }
    if (item.anonymization_necessity === 'low') {
      return 4
    }
    if (item.anonymization_necessity === 'verylow') {
      return 5
    }
    return 0
  }
  useEffect(() => {
    const uniqueEntities = Array.from(new Set(anonymizations?.ners?.map(item => item.entity)))
    if (anonymizations?.ners && initialOrder.length !== uniqueEntities.length) {
      const anonymizationStatus = uniqueEntities.map(entity => {
        const relatedItems = anonymizations.ners.filter(item => item.entity === entity)
        return relatedItems[0]
      })

      const sortedUniqueList = anonymizationStatus.sort((a, b) => {
        const priority = { image: 2, filled_shape: 2, 'None-Identifier': 3 }
        const aPriority = getItemPriority(a)
        const bPriority = getItemPriority(b)

        if (aPriority !== bPriority) {
          return aPriority - bPriority // Sort by priority
        } else {
          return initialOrder.indexOf(a.entity) - initialOrder.indexOf(b.entity)
        }
      })
      setCombinedList(sortedUniqueList)
      setInitialOrder(uniqueEntities)
      const toggles = sortedUniqueList.reduce((acc, item) => {
        acc[item.entity] = item.annonymize
        return acc
      }, {})
      setAnonymizeToggles(toggles)
    }
  }, [anonymizations, initialOrder])

  // Effect hook to update userQuery when selectedFileData changes
  useEffect(() => {
    if (selectedFileData) {
      setUserQuery(selectedFileData.userQuery || '')
    }
  }, [selectedFileData])

  useEffect(() => {
    if (selectedFileInfo) {
      if (anonymizations?.ners) {
        if (selectedFileData.anonymization_verdict_processor_entities_blob_path) {
          // Call the handler with no parameters to default to Stufe 2
          handleAnonymizationLevelChange({ target: { value: 'Stufe 2 - Erweiterte Anonymisierung' } })
        }
      }
    }
  }, [selectedFileInfo])

// useEffect to update the local toggle state upon changes in the Redux store
  useEffect(() => {
    // Generate toggle states based on the latest Redux state (after updates are dispatched)
    if (anonymizations?.ners) {
      const newToggles = anonymizations.ners.reduce((acc, curr) => {
        acc[curr.entity] = curr.annonymize
        return acc
      }, {})
      setAnonymizeToggles(newToggles)
    }
  }, [anonymizations?.ners])

  const handleToggleChange = (entity) => {
    if (anonymizations?.ners) {
      // Map through the ners to update the 'annonymize' status of the matched entity
      const newResultsJson = anonymizations.ners.map(annotation => {
        if (annotation.entity === entity) {
          // Toggle the anonymize status
          return { ...annotation, annonymize: !annotation.annonymize }
        }
        return annotation
      })

      // Prepare the updated anonymizations object with the new results
      const newAnonymizations = {
        ...anonymizations,
        ners: newResultsJson
      }

      // Dispatch the update action to Redux to update the global state
      dispatch(updateAnonymizationMapping({
        fileId: selectedFileInfo.id,
        newAnonymization: newAnonymizations
      }))

      // Update local toggle state
      const newAnonymizeToggles = {
        ...anonymizeToggles,
        [entity]: !anonymizeToggles[entity]
      }
      setAnonymizeToggles(newAnonymizeToggles)
    } else {
      console.error('Anonymizations results_json is undefined.')
    }
  }

  const getAnonymizationImportance = (item) => {
    if (item.type === 'image' || item.type === 'filled_shape') {
      return 'Stufe 4 - Umfassende Anonymisierung'
    }
    if (item.legal_cetergory in anonymizationImportanceMapping) {
      return anonymizationImportanceMapping[item.legal_cetergory] || 'Unknown-Unknown'
    }
    if (item.category in anonymizationImportanceMapping) {
      return anonymizationImportanceMapping[item.category] || 'Unknown-Unknown'
    } else {
      if (item.Relevance) {
        return 'Unknown-' + item.Relevance
      } else {
        return 'Unknown-Unknown'
      }
    }
  }

  const openAnonymizationDialog = () => setIsAnonymizationDialogOpen(true)
  const closeAnonymizationDialog = () => setIsAnonymizationDialogOpen(false)

  const handleAnonymizationSelection = async (selectedAnonymizationOption, anonymizeImages) => {
    setIsLoading(true)
    let errorReceived = {}
    errorReceived.message = ''
    try {
      await SecureFileStatusManager.setFileBusy(userId, selectedFileInfo.id)
      // dispatch empty anonymization mapping to reset the state
      setIsLoading(false)
      const newAnon = await anonymizeVerdict(selectedFileInfo.id, userId, userIdToken, selectedAnonymizationOption, userQuery, anonymizeImages)
      dispatch(updateAnonymizationMapping({
        fileId: selectedFileInfo.id,
        newAnonymization: newAnon
      }
        ))
    } catch (error) {
      errorReceived = error
      if (error.message === 'FileBusyError') {
        setAnonymizationErrorMessage('file_busy')
        setIsAnonymizationErrorDialogOpen(true)
        return // Exit the function or handle accordingly
      } else if (error.message === 'Invalid language') {
        setAnonymizationErrorMessage('file_language_not_german')
        setIsAnonymizationErrorDialogOpen(true)
        return // Exit the function or handle accordingly
      } else if (error.message === 'Invalid file format or empty file') {
        setAnonymizationErrorMessage('invalid_file')
        setIsAnonymizationErrorDialogOpen(true)
        return // Exit the function or handle accordingly
      }
      await SecureFileStatusManager.clearFileBusy(userId, selectedFileInfo.id)
      // console.error('Error:', error)
      if (error.message === 'RateLimitError') {
        setTimeToRetry(error.timeToWait)
        setRateLimitDialogOpen(true) // Open the dialog for 429 errors
      } else if (error.message === 'UnauthorizedError') {
        const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
        if (confirm) {
          await auth.signOut()
          navigate('/signin')
        }
      }
    } finally {
      // Ensure clearFileBusy is not called again if FileBusyError was caught
      if (errorReceived.message !== 'FileBusyError') {
        await SecureFileStatusManager.clearFileBusy(userId, selectedFileInfo.id)
      }
      setIsLoading(false)
      closeAnonymizationDialog()
    }
  }

  const handleAnonymizationLevelChange = (event) => {
    let level
    if (event && event.target && event.target.value) {
      level = event.target.value
    } else {
      level = 'Stufe 4 - Umfassende Anonymisierung'
    }
    // console.log('Selected level:', level)
    setSelectedAnonymizationLevel(level)

    // Define the cutoff level based on the selection
    const cutoffLevel = parseInt(level.split(' ')[1], 10)

    // Initialize new states for toggles
    const updatedAnonymizeToggles = {}

    if (anonymizations?.ners) {
      // Create a deep copy of the results_json to avoid direct state mutation
      const newResultsJson = anonymizations.ners.map(annotation => {
        const itemLevel = parseInt(getAnonymizationImportance(annotation).split(' ')[1], 10)
        // console.log('annotation', annotation)
        // console.log('Item level:', itemLevel)
        const shouldAnonymize = itemLevel <= cutoffLevel

        // Update the toggle state for each item
        updatedAnonymizeToggles[annotation.entity] = shouldAnonymize

        // Return the updated annotation
        return { ...annotation, annonymize: shouldAnonymize }
      })

      // Prepare the updated anonymizations object
      const newAnonymizations = {
        ...anonymizations,
        ners: newResultsJson
      }

      // Dispatch the update action to Redux
      dispatch(updateAnonymizationMapping({
        fileId: selectedFileInfo.id,
        newAnonymization: newAnonymizations
      }))
    }

    // Update the toggle states
    setAnonymizeToggles(updatedAnonymizeToggles)
  }

  const countWords = (str) => {
    return str.split(' ').filter(function (n) { return n !== '' }).length
  }

  const handlePreviewFileClick = () => {
    dispatch(setPDFViewerActive(true))
  }

  const getFileStatus = () => {
    if (selectedFileData.busySince) {
      return 'Wird anonymisiert'
    }
    if (selectedFileData.anonymization_mistral_processor_entities_blob_path && selectedFileData.anonymization_verdict_processor_entities_blob_path) {
      return 'Vollständig anonymisiert'
    }
    if (selectedFileData.anonymization_mistral_processor_entities_blob_path) {
      return 'Anonymisiert mit Prompt'
    }
    if (selectedFileData.anonymization_verdict_processor_entities_blob_path) {
      return 'Anonymisiert mit Urteils-Prompt'
    }
    if (selectedFileData.paragraphs_blob_path) {
      return 'Verarbeitet'
    }
    return 'Nicht anonymisiert'
  }

  const isAnonymizeDisabled = Boolean(
      selectedFileData.anonymization_mistral_processor_entities_blob_path ||
      selectedFileData.anonymization_verdict_processor_entities_blob_path ||
      selectedFileData.busySince
  )

  const handleRefinementDialogClose = () => {
    setRefinementDialogOpen(false)
    setUserQuery(selectedFileData.userQuery)
  }

  return (
      <div className="file-info-section">
        {isLoading && (
            <div className="login-overlay">
              <CircularProgress/>
            </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 1, textAlign: 'left' }}>
            <Typography variant="h5" className="file-name-header"
                        style={{ fontWeight: 'bold' }}>{selectedFileInfo.text}</Typography>
          </div>
          <Button
              className="preview-btn action"
              style={{ marginLeft: 'auto' }}
              variant="outlined"
              onClick={handlePreviewFileClick}
          >
            {/* <ZoomInIcon/> /!* Magnifier Icon *!/ */}
            Vorschau
          </Button>
        </div>
        <div className="file-status">
          <p><span style={{ fontWeight: 'bold' }}>Status:</span> {getFileStatus()} (Sie verwenden die Testversion von AnonLab. Die ersten 10 Seiten werden anonymisiert.)</p>
          {hasAnonymizationsData && (
              <>
                {getFileStatus() === 'Anonymisiert mit Prompt' && (
                    <p><span style={{ fontWeight: 'bold' }}>Prompt:</span> &apos;{selectedFileData.userQuery}&apos;</p>
                )}
                <DownloadRedactedButton
                    fileId={selectedFileData.id}
                    userId={userId}
                    userIdToken={userIdToken}
                    ners={selectedFileData.mapping.ners}
                    setIsLoading={setIsLoading}
                    setAnonymizationErrorMessage={setAnonymizationErrorMessage}
                    hasAnonymizationsData={hasAnonymizationsData}
                />
                {/*  <Button */}
              {/*    variant="outlined" */}
              {/*    color="primary" */}
              {/*    className="preview-btn action" */}
              {/*    style={{ marginTop: '0.5rem' }} */}
              {/*    onClick={() => setRefinementDialogOpen(true)} */}
              {/*    disabled={!hasAnonymizationsData} */}
              {/* > */}
              {/*  Download anonymisierte PDF */}
              {/* </Button> */}
                </>
          )}
          <div className="file-stage-visualization">
            {selectedFileData.busySince &&
                <LinearProgress
                    variant="determinate"
                    value={(getFileStatus() === 'Approved' ? 100 : getFileStatus() === 'Anonymized' ? 50 : getFileStatus() === 'Parsed' ? 25 : 0)}
                    className={dynamicClass}
                    style={dynamicProgressStyle}
                />
            }
          </div>
        </div>
        <div className="button-container" style={{ textAlign: 'center', display: 'flex', justifyContent: 'left' }}>
          {isAnonymizeDisabled && getFileStatus() === 'Anonymisiert mit Prompt' ? (
              <div>
                <Typography variant="h5" style={{ textAlign: 'left', marginTop: '2.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>Anonymisierung verfeinern</Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    className="preview-btn action"
                    onClick={() => setRefinementDialogOpen(true)}
                >
                  Verfeinern
                </Button>
              </div>
          ) : (
              <Button
                  variant="outlined"
                  className={`preview-btn ${!isAnonymizeDisabled ? 'action' : ''}`}
                  style={buttonStyle}
                  onClick={openAnonymizationDialog}
                  disabled={isAnonymizeDisabled}
              >
                Anonymisieren
              </Button>
          )}
          <AnonymizationOptionsDialog
              open={isAnonymizationDialogOpen}
              onAnonymize={handleAnonymizationSelection}
              onClose={closeAnonymizationDialog}
              userQuery={userQuery}
              setUserQuery={setUserQuery}
          />
          <RateLimitDialog
              open={isRateLimitDialogOpen}
              onClose={handleCloseRateLimitDialog}
              onContact={handleContact}
              timeToRetry={timeToRetry}
              numberPages={numberPages}
          />
          <AnonymizationErrorDialog
              open={isAnonymizationErrorDialogOpen}
              onClose={handleCloseAnonymizationErrorDialog}
              onContact={handleContact}
              errorMessage={anonymizationErrorMessage}
          />
        </div>
        {hasAnonymizationsData && selectedFileData.anonymization_verdict_processor_entities_blob_path && (
            <>
              <div className="hints-section">
                <Typography variant="h5" style={{ textAlign: 'center', margin: '0.5rem 0' }}>
                  Anonymisierungsstufe
                  <Tooltip title={anonymizationLevelsExplanation} placement="top">
                    <IconButton size="small" className="info-icon">
                      <HelpOutlineIcon fontSize="inherit"/>
                    </IconButton>
                  </Tooltip>
                </Typography>
                <div className="anonymization-levels">
                  {[
                    'Stufe 1 - Einfache Anonymisierung: Klagepartei, Gegenpartei, Opfer, Zeuge, Minderjährige, Menschen mit Behinderungen',
                    'Stufe 2 - Erweiterte Anonymisierung: + (Verteidigung, Sachverständige, Bezugspersonen)',
                    'Stufe 3 - Vollständige Anonymisierung: + (Medien, Zuschauer)',
                    'Stufe 4 - Umfassende Anonymisierung: + (Gericht, Gerichtsschreiber:innen, öffentliche Figuren, Behörden, Mitarbeiter:innen des Gerichts, Übersetzer:innen, fallbezogene Informationen)'
                  ].map((level) => (
                      <div key={level} className="anonymization-level-item">
                        <input
                            type="radio"
                            id={level}
                            name="anonymizationLevel"
                            value={level}
                            checked={selectedAnonymizationLevel === level}
                            onChange={handleAnonymizationLevelChange}
                        />
                        <label htmlFor={level}>{level}</label>
                        <Tooltip title={anonymizationLevelsInfo[level]} placement="top">
                          <IconButton size="small" className="info-icon">
                            <HelpOutlineIcon fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                      </div>
                  ))}
                </div>
              </div>
            </>
        )}
        <div>
          <RefinementDialog
              open={refinementDialogOpen}
              handleClose={handleRefinementDialogClose}
              userQuery={userQuery}
              setUserQuery={setUserQuery}
              countWords={countWords}
              selectedFileData={selectedFileData}
              handleQuerySubmit={() => handlePromptAnonymizationSubmit({
                userId,
                selectedFileInfo,
                userIdToken,
                setIsLoading,
                setRefinementDialogOpen,
                setIsAnonymizationErrorDialogOpen,
                setTimeToRetry,
                setNumberPages,
                setRateLimitDialogOpen,
                closeAnonymizationDialog,
                userQuery
              })}
          />
        </div>

        {hasAnonymizationsData && (
            <>
              <Typography variant="h5"
                          style={{ textAlign: 'left', marginTop: '2.5rem', marginBottom: '0.5rem', fontWeight: 'bold' }}>Liste an Entitäten</Typography>
              <div className="toggle-grid">
                <div className="toggle-row header">
                  <Typography variant="subtitle1" className="toggle-item"
                              style={{ fontWeight: 'bold', textAlign: 'center' }}> </Typography>
                  <Typography variant="subtitle1" className="toggle-item"
                              style={{ fontWeight: 'bold', textAlign: 'left' }}>Entität</Typography>
                  <Typography variant="subtitle1" className="toggle-item"
                              style={{ fontWeight: 'bold', textAlign: 'left' }}>Kategorie</Typography>
                  <Typography variant="subtitle1" className="toggle-item"
                              style={{ fontWeight: 'bold', textAlign: 'left' }}>Wichtigkeit</Typography>
                </div>
                {combinedList.map((item, index) => (
                    <div key={index} className="toggle-row">
                      <div
                          className={item.Application === 'Not Relevant' ? 'toggle-item toggle-item-orange' : item.type === 'image' || item.type === 'filled_shape' ? 'toggle-item toggle-item-red' : 'toggle-item'}>
                        <Switch
                            checked={anonymizeToggles[item.entity]}
                            onChange={() => handleToggleChange(item.entity)}
                            size="small"
                        />
                      </div>
                      <Typography variant="body1" className="toggle-item">{item.entity}</Typography>
                      <Typography variant="body1" className="toggle-item">
                        {item.legal_cetergory ? item.legal_cetergory : item.category}
                      </Typography>
                      <Typography variant="body1"
                                  className="toggle-item"> {item.anonymization_necessity} </Typography>
                    </div>
                ))}
              </div>
            </>
        )}

      </div>
  )
}

FileInfoSection.propTypes = {
  selectedFileInfo: PropTypes.any,
  updateSelectedFileInfo: PropTypes.func.isRequired,
  refreshFolderTreeData: PropTypes.func.isRequired
}
export default FileInfoSection
