import React from 'react'
import PropTypes from 'prop-types'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import 'pdfjs-dist/build/pdf.worker.entry'

const FileUploader = ({ onFileContent }) => {
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
            console.log('Selected file:', selectedFile)
            const fileType = selectedFile.type
            const reader = new FileReader()
            reader.onload = async (e) => {
                const fileArrayBuffer = e.target.result
                const uint8Array = new Uint8Array(fileArrayBuffer)
                if (fileType === 'application/pdf') {
                    console.log('PDF file selected, starting text extraction...')
                    await extractTextFromPDF(uint8Array)
                } else if (fileType === 'text/plain') {
                    console.log('Text file selected, reading content...')
                    const text = new TextDecoder().decode(uint8Array)
                    console.log('Text content:', text)
                    onFileContent(text, 'text')
                } else {
                    console.error('Unsupported file type:', fileType)
                }
            }
            reader.onerror = (e) => {
                console.error('Error reading file:', e)
            }
            reader.readAsArrayBuffer(selectedFile)
        }
    }

    const extractTextFromPDF = async (pdfData) => {
        try {
            console.log('Starting PDF text extraction...')
            const loadingTask = pdfjsLib.getDocument({ data: pdfData })
            const pdf = await loadingTask.promise
            console.log('PDF loaded, number of pages:', pdf.numPages)
            let extractedText = ''

            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum)
                console.log('Extracting text from page:', pageNum)
                const textContent = await page.getTextContent()
                const pageText = textContent.items.map(item => item.str).join(' ')
                extractedText += ` ${pageText}`
            }

            console.log('Extracted text:', extractedText)
            onFileContent(extractedText, 'pdf')
        } catch (error) {
            console.error('Error extracting text from PDF:', error)
        }
    }

    return (
        <div>
            <input type="file" onChange={handleFileChange} accept=".pdf, .txt" />
        </div>
    )
}

FileUploader.propTypes = {
    onFileContent: PropTypes.func.isRequired
}

export default FileUploader
