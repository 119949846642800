import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, Typography } from '@material-ui/core'

import SearchBar from './Components/SearchBar'
import FilterPanel from './Components/FilterPanel'
import SearchResult from './Components/SearchResult'

import fetchFakeData from './FetchRequests/fetchFakeData'
import fetchSQL from './FetchRequests/fetchSQL'
import fetchClusters from './FetchRequests/fetchClusters'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import '../../../styles/VerdictSearchChat.scss'
import { auth } from '../../../firebase/firebase'
import { useNavigate } from 'react-router-dom'

function VerdictSearchChat () {
  const userId = useSelector((state) => state.auth.userId)
  const userIdToken = useSelector((state) => state.auth.userIdToken)
  const headerHeight = useSelector((state) => state.layout.headerHeight)
  const footerHeight = useSelector((state) => state.layout.footerHeight)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [lastQuery, setlastQuery] = useState('')
  const [searchStarted, setSearchStarted] = useState(false)
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [results, setResults] = useState([])
  const [combinedQuery, setCombinedQuery] = useState('')
  const [filters, setFilters] = useState([])
  const [graphData, setGraphData] = useState({ nodes: [], edges: [] })
  const [loading, setLoading] = useState(false) // State to handle loading
  const [filterLoading, setFilterLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(20)
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState(['topic_title', 'court_meta_de', 'year', 'language']
      .reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
  const [appliedFilters, setAppliedFilters] = useState({ ...selectedFilters })

  useEffect(() => {
    function handleResize () {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!isMobile && isFilterVisible) {
      setIsFilterVisible(false)
    }
  }, [isMobile, isFilterVisible])

  const handleFilterToggle = () => {
    setIsFilterVisible(!isFilterVisible)
  }

  const handleSearch = async (searchQuery, selectedFilters) => {
    setSearchStarted(true)
    setLoading(true)
    setFilterLoading(true)
    setlastQuery(searchQuery)
    try {
      const data = await fetchSQL(searchQuery, selectedFilters, userIdToken)
      setLoading(false)
      setResults(data.results)
      setCombinedQuery(data.combined_query)
      // Fetch the Graph and Filter data based on the results
      const graph = await fetchFakeData(searchQuery, selectedFilters)
      const clusters = await fetchClusters(data.results, data.combined_query, userIdToken)
      // Set the filters and graph data
      setFilters(clusters.search_results_clustered)
      setGraphData(graph.graph)
      // Loading for filters and graph data is done
      setFilterLoading(false)
    } catch (error) {
      console.error('Error fetching data: ', error)
      if (error.message === 'UnauthorizedError') {
        const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
        if (confirm) {
          await auth.signOut()
          navigate('/signin')
        }
      }
    }
    setLoading(false)
  }
  // This will calculate the first and last elements to be displayed on the current page
  const indexOfLastResult = currentPage * itemsPerPage
  const indexOfFirstResult = indexOfLastResult - itemsPerPage
  const currentResults = results.slice(indexOfFirstResult, indexOfLastResult)
  // Handle changing the page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber)
  return (
      <div className="text-search-pwa-container" style={{ marginTop: headerHeight }}>
        {!searchStarted && <Typography variant="h5">VerdictLLMs Retrieval-Modul: Einsatz für 600K Schweizer Urteile </Typography>}
        {loading && (
            <div className="loading-overlay">
              <CircularProgress />
            </div>
        )}
        <div className={`text-search-pwa-content ${searchStarted ? 'search-active' : ''}`}>
          {/* mainly for mobile */}
          <SearchBar
              onSearch={(query) => handleSearch(query, [])}
              searchActive={searchStarted}
              isMobile={isMobile}
              isFilterVisible={isFilterVisible}
              handleFilterToggle={handleFilterToggle}
              filters={filters}
              graphData={graphData}
              setSelectedFilters={setSelectedFilters}
              setAppliedFilters={setAppliedFilters}
          />
          {/* mainly for desktop */}
          {searchStarted && (
              <div className="lower-sections">
                {!isMobile && <FilterPanel
                    loading={filterLoading}
                    setLoading={setFilterLoading}
                    filters={filters}
                    graphData={graphData}
                    results={results}
                    setResults={setResults}
                    setFilters={setFilters}
                    combinedQuery={combinedQuery}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                />}
                <SearchResult
                    results={currentResults}
                    totalItems={results.length}
                    itemsPerPage={itemsPerPage}
                    handlePageChange={handlePageChange}
                    currentQuery={lastQuery}
                />
              </div>
          )}
        </div>
      </div>
  )
}

export default VerdictSearchChat
