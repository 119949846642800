import { API_ENDPOINTS } from '../../../../config/apiConfig'

class RateLimitError extends Error {
  constructor (message, timeToWait, numberPages) {
    super(message) // Pass message to parent constructor
    this.message = 'RateLimitError'
    this.timeToWait = timeToWait
    this.numberPages = numberPages
  }
}

const anonymizeVerdict = async (fileId, userId, userIdToken, selectedAnonymizationOption, userQuery = null, anonymizeImages) => {
  const apiUrl = API_ENDPOINTS.verdictAnonymizer
  const payload = {
    fileId,
    userId,
    processor: selectedAnonymizationOption,
    userQuery,
    anonymizeImages
  }

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userIdToken}`
      },
      body: JSON.stringify(payload)
    })

    const data = await response.json()

    if (response.status === 401) {
      throw new Error('UnauthorizedError')
    } else if (response.status === 429) {
      const timeToWaitSeconds = Math.ceil(data.time_until_next_request)
      const numberPages = data.number_of_pages
      console.log('timeToRetry', timeToWaitSeconds)
      let timeToWaitFormatted = ''
      if (timeToWaitSeconds > 3600) {
        timeToWaitFormatted = Math.ceil(timeToWaitSeconds / 3600) + ' Stunden'
      } else {
        timeToWaitFormatted = Math.ceil(timeToWaitSeconds / 60) + ' Minuten'
      }
      throw new RateLimitError('RateLimitError', timeToWaitFormatted, numberPages)
    } else if (response.status === 500) {
      throw new Error('Invalid file format or empty file')
    } else if (response.status === 411) {
      throw new Error('Invalid language')
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}. Message: ${data.error || 'Unknown error'}`)
    }
    return data
  } catch (error) {
    console.error('An error occurred:', error)
    throw error // This will now include the custom RateLimitError if applicable
  }
}

export default anonymizeVerdict
