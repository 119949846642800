import { API_ENDPOINTS } from '../../../../../config/apiConfig'
import { auth } from '../../../../../firebase/firebase' // Update this path based on actual location

const manualAdditionHandler = async (fileId, entity, userIdToken, userId, navigate, location) => {
    const apiUrl = API_ENDPOINTS.manualAddition

    try {
        const payload = {
            fileId,
            entity,
            userId
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userIdToken}`
            },
            body: JSON.stringify(payload)
        })

        const data = await response.json()

        if (response.status === 200) {
            return data
        } else if (response.status === 401) {
            const confirm = window.confirm('Your session has expired. Please sign out and sign in again.')
            if (confirm) {
                await auth.signOut()
                navigate('/signin', { state: { from: location.pathname } })
            }
        } else {
            console.error('Error:', data.error)
        }
    } catch (error) {
        console.error('An unexpected error occurred:', error)
    }
}

export default manualAdditionHandler
