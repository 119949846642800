import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Dienstleistungen.module.scss'
import { IoIosArrowForward } from 'react-icons/io'
import { isMobile } from 'react-device-detect'
import Button from '../Button/Button'

function Dienstleistungen ({ navigationItems, headline, buttonLabel, id }) {
    // Using useState to manage the active navigation item index
    const [activeNavItemIndex, setActiveNavItemIndex] = useState(0)
    const [maxHeight, setMaxHeight] = useState(0)
    const navListRef = useRef(null)

    // Event handler for clicking a navigation item
    const handleNavItemClick = (index) => {
        setActiveNavItemIndex(index)
    }

    useEffect(() => {
        // Calculate the maximum height of all child elements of navList
        if (navListRef.current) {
            const maxItemHeight = Array.from(navListRef.current.children).reduce((max, child) => {
                return child.offsetHeight > max ? child.offsetHeight : max
            }, 0)
            setMaxHeight(maxItemHeight)
        }
    }, [navigationItems])

    return (
        <section className={styles.pageWrapper} id={id}>
            <div className={styles.headlineWrapper}>
                <h2>{headline}</h2>
            </div>
            <div className={styles.dienstleistungen} ref={navListRef} style={isMobile ? {} : { height: `${maxHeight}px` }}>
                <div className={styles.navList}>
                    {navigationItems.map((item, index) => (
                        <div
                            key={item.title}
                            className={`${styles.navItem} ${index === activeNavItemIndex ? styles.active : ''}`}
                            onClick={() => handleNavItemClick(index)}
                        >
                            <span><h2>{isMobile ? item.title_mobile : item.title}</h2></span>
                            <IoIosArrowForward className={styles.arrowIcon} size={40}/>
                        </div>
                    ))}
                </div>
                <div className={styles.contentArea}>
                    <h3>{navigationItems[activeNavItemIndex]?.teaser}</h3>
                    {(isMobile && navigationItems[activeNavItemIndex]?.text_mobile ? navigationItems[activeNavItemIndex]?.text_mobile : navigationItems[activeNavItemIndex]?.text).split('\n').map((line, index) => (
                        <p key={index} className={styles.lineWithExtraSpacing}>{line}</p>
                    ))}
                    <ul>
                        {navigationItems[activeNavItemIndex]?.facts?.map((fact, index) => (
                            <li key={index}>{fact}</li>
                        ))}
                    </ul>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'left'
                    }}>
                        <Button
                            label={buttonLabel}
                            slug={buttonLabel === 'Jetzt Ausprobieren ↗' ? '/playground' : '#kontakt'}
                            isCTA
                        />
                    </div>

                </div>
            </div>
        </section>
    )
}

Dienstleistungen.propTypes = {
    navigationItems: PropTypes.any.isRequired,
    headline: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string,
    id: PropTypes.string
}

export default Dienstleistungen
