import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

export default function Button ({ isCTA, isTeaser, label, onClick, slug, disabled }) {
    const buttonClasses = `${styles.button} ${isCTA ? styles.action : ''} ${isTeaser ? styles.teaser : ''}`

    // Decide between a button with an onClick handler or a link for navigation
    return onClick ? (
        <button
            className={buttonClasses}
            aria-label={label}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </button>
    ) : (
        <Link
            to={slug}
            className={buttonClasses}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            aria-label={label}
        >
            {label}
        </Link>
    )
}

Button.propTypes = {
    isCTA: PropTypes.bool,
    isTeaser: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    slug: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    disabled: PropTypes.bool
}
