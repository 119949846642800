import { createSlice } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        headerHeight: 0,
        footerHeight: 0,
        isMobileDevice: isMobile
    },
    reducers: {
        setHeaderHeight: (state, action) => {
            state.headerHeight = action.payload
        },
        setFooterHeight: (state, action) => {
            state.footerHeight = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setHeaderHeight, setFooterHeight } = layoutSlice.actions

export default layoutSlice.reducer
