import { TextareaAutosize, Typography, CircularProgress } from '@mui/material'
import React, { useState, useRef } from 'react'
import germanyFlag from '../../../../assets/images/icons/germany.png'
import switzerlandFlag from '../../../../assets/images/icons/switzerland.png'
import Button from '../../../../components/Button/Button'
import AnonymizationTabSettingsMenu from './AnonymizationTabSettingsMenu'
import AnonymizeUserInputText from '../ServerLogics/AnonymizeUserInputText'
import FileUploader from './FileUploader' // Adjust the import path as necessary

const AnonymizeTab = () => {
    const [busy, setBusy] = useState(false)
    const [userInputText, setUserInputText] = useState('')
    const [userInputTextAnonymized, setUserInputTextAnonymized] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [serverLocation, setServerLocation] = useState('Deutschland')
    const [anonymizationDegree, setAnonymizationDegree] = useState('Normal')
    const fileInputRef = useRef(null)

    const handleTextChange = (event) => {
        setUserInputText(event.target.value)
    }

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleAnonymizeClick = async () => {
        setBusy(true)
        const { anonymizedWords, replacedText } = await AnonymizeUserInputText(userInputText)

        const createTooltipHtml = (text, tooltip) => {
            return `<span class="tooltip" title="${tooltip}" style="color: orange; cursor: pointer;">${text}</span>`
        }

        let anonymizedHtmlContent = replacedText
        for (const [label, word] of Object.entries(anonymizedWords)) {
            const regex = new RegExp(`\\b${label}\\b`, 'g')
            anonymizedHtmlContent = anonymizedHtmlContent.replace(regex, createTooltipHtml(label, word))
        }

        setUserInputTextAnonymized(anonymizedHtmlContent)
        setBusy(false)
    }

    const handlePasteClick = async () => {
        try {
            const textFromClipboard = await navigator.clipboard.readText()
            const concatenatedText = userInputText + textFromClipboard
            setUserInputText(concatenatedText)
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err)
        }
    }

    const handleFileUploadClick = () => {
        fileInputRef.current.click()
    }

    const handleFileContent = (content, fileType) => {
        if (fileType === 'pdf' || fileType === 'text') {
            setUserInputText(content)
        }
    }

    return (
        <div className="anonymize-tab">
            <div className="settings-container" onClick={handleSettingsClick}>
                <Typography variant="h6" className="settings-dropdown">
                    Einstellungen
                </Typography>
                <span className="material-symbols-outlined settings-icon">
                    settings
                </span>
            </div>
            <AnonymizationTabSettingsMenu
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                serverLocation={serverLocation}
                setServerLocation={setServerLocation}
                anonymizationDegree={anonymizationDegree}
                setAnonymizationDegree={setAnonymizationDegree}
            />
            <div className="content-wrapper">
                <div className="text-field-container">
                    <div className="input-section">
                        <div className="textarea-container">
                            <TextareaAutosize
                                className="text-input"
                                minRows={5}
                                placeholder="Write or paste text, or upload a document."
                                value={userInputText}
                                onChange={handleTextChange}
                            />
                        </div>
                        {busy && (
                            <div className="busy-overlay">
                                <CircularProgress />
                            </div>
                        )}
                        <span
                            className="material-symbols-outlined left-side-icon"
                            onClick={handleFileUploadClick}
                            style={{ cursor: 'pointer' }}
                        >
                            cloud_upload
                        </span>
                        <FileUploader onFileContent={handleFileContent} />
                        <span
                            id="paste-icon"
                            className="material-symbols-outlined right-side-icon"
                            onClick={handlePasteClick}
                            style={{ cursor: 'pointer' }}
                        >
                            content_paste
                        </span>
                    </div>
                    <div className="button-wrapper">
                        <Button
                            label="Anonymisieren"
                            slug="Anonymisieren"
                            isCTA={!busy}
                            onClick={handleAnonymizeClick}
                            disabled={busy}
                        />
                        <div className="token-info">
                            <Typography>130 Tokens</Typography>
                            {serverLocation === 'Deutschland' ? (
                                <img src={germanyFlag} alt="German Flag"/>
                            ) : (
                                <img src={switzerlandFlag} alt="Swiss Flag"/>
                            )}
                            <span className="material-symbols-outlined lock-icon">lock</span>
                        </div>
                    </div>
                </div>
                <div className="text-field-container">
                    <div className="output-section">
                        <div className="textarea-container">
                            <div
                                dangerouslySetInnerHTML={{ __html: userInputTextAnonymized }}
                                style={{ minHeight: '100px', padding: '8px' }}
                            />
                        </div>
                        <span className="material-symbols-outlined left-side-icon">cloud_download</span>
                        <span className="material-symbols-outlined right-side-icon">content_copy</span>
                    </div>
                    <div className="button-wrapper">
                        <Button
                            label="Kopieren"
                            slug="Kopieren"
                            isCTA
                            onClick={() => { /* Handle copy action */ }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnonymizeTab
