import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { auth, db } from './firebase'
import { GoogleAuthProvider, updatePassword, getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { createUser } from './userOperations/createUser'
import styles from './SignIn.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setUserIdScreenName } from '../slices/authSlice'
import Button from '../components/Button/Button'
import TextField from '@mui/material/TextField'
import { isMobile } from 'react-device-detect'
import registerUser from './userOperations/registerUser'
import loginUser from './userOperations/loginUser'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import PasswordDialog from './userOperations/PasswordDialog'
import { getDoc, doc, updateDoc } from 'firebase/firestore'
import TokenRefreshComponent from './TokenRefreshComponent'

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
}

const SignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }

  const dispatch = useDispatch()
  const [registrationError, setRegistrationError] = useState('')
  let headerHeight = useSelector((state) => state.layout.headerHeight)
  headerHeight = `calc(${headerHeight}px)`

  const [formKey, setFormKey] = useState(Date.now())
  const emailInputRef = useRef(null)
  const passwordInputRef = useRef(null)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organization, setOrganization] = useState('')
  const [isFormValid, setFormValid] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [newPassword, setNewPassword] = useState('')

  const [checked, setChecked] = useState(true)
  const [showRegistration, setShowRegistration] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
    const handleCheckboxChange = (event) => {
      setChecked(event.target.checked)
      localStorage.setItem('checkedState', event.target.checked)
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // One or more characters that are not whitespace, followed by an @ sign, followed by one or more characters that are not whitespace, followed by a dot, followed by one or more characters that are not whitespace
  // Regular expression for password validation
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/ // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        if (registrationError.length > 0) {
            setRegistrationError('')
        }
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
        if (registrationError.length > 0) {
            setRegistrationError('')
        }
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
    }

    const handleOrganizationChange = (event) => {
        setOrganization(event.target.value)
    }

    const handleEmailBlur = () => {
        const isValidEmail = emailRegex.test(email)
        setEmailError(email.length > 0 && !isValidEmail ? 'Ungültige E-Mail' : '')
    }

    const handlePasswordBlur = () => {
        const isValidPassword = passwordRegex.test(password)
        setPasswordError(password.length > 0 && !isValidPassword ? 'min. 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Sonderzeichen @#$%^&*' : '')
    }

    const validateForm = () => {
        const hasStartedEmail = email.length > 0
        const hasStartedPassword = password.length > 0

        const isValidEmail = hasStartedEmail && emailRegex.test(email)
        const isValidPassword = hasStartedPassword && passwordRegex.test(password)

        setFormValid(isValidEmail && isValidPassword)
    }

    // Call validateForm whenever email or password changes
    useEffect(() => {
        validateForm()
    }, [email, password])

    useEffect(() => {
        const timer = setTimeout(() => {
            setEmail('')
            setPassword('')
        }, 50) // delay state setting

        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        setFormKey(Date.now()) // change form key on component mount
    }, [])

    // useEffect(() => {
    //     const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
    //         if (user) {
    //             console.log('useruser:', user)
    //             if (user.emailVerified) {
    //                 let savedCheckedState = JSON.parse(localStorage.getItem('checkedState'))
    //                 if (savedCheckedState === null) {
    //                     savedCheckedState = checked
    //                 }
    //                 // const success = await createUser(user.email, user.uid, savedCheckedState)
    //                 // console.log('success:', success)
    //                 // if (success) {
    //                     dispatch(setUserIdScreenName({
    //                         userId: user.uid,
    //                         userScreenName: user.displayName ? user.displayName : user.email,
    //                         userIdToken: await user.getIdToken()
    //                     }))
    //                     navigate(from)
    //                 // } else {
    //                 //     console.error('Failed to create user in Firestore')
    //                 // }
    //             } else {
    //                 setRegistrationError('Bitte bestätigen Sie Ihre E-Mail-Adresse, um sich anzumelden.')
    //             }
    //             if (user.setNewPassword) {
    //                 setNewPasswordRequired(true)
    //             }
    //         }
    //     })
    //     // Cleanup function for useEffect
    //     return () => {
    //         unregisterAuthObserver()
    //         unregisterTokenObserver()
    //     }
    // }, [navigate, dispatch])

    const handleRegisterClick = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        setRegistrationError('') // Reset the registration error message

        try {
            if (isFormValid) {
                const user = await registerUser(email, password)
                const success = await createUser(user.email, user.uid, checked, firstName, lastName, organization, false)
                // Handle successful registration
                // navigate to another page or show a success message
            }
        } catch (error) {
            if (error.message === 'This email is already in use.') {
                setRegistrationError('Diese E-Mail-Adresse wird bereits verwendet. Bitte melden Sie sich an oder verwenden Sie eine andere E-Mail-Adresse zur Registrierung.')
            } else {
                setRegistrationError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        }
        setIsLoading(false)
    }

    const handleLoginClick = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        setRegistrationError('') // Reset the error message

        try {
            if (email && password) { // Ensure email and password are provided
                const user = await loginUser(email, password)
                // Check if the user's email is verified
                if (!user.emailVerified) {
                    setRegistrationError('Bitte bestätigen Sie Ihre E-Mail-Adresse, um sich anzumelden.')
                //     logout user
                    await auth.signOut()
                } else {
                    const userDoc = await getDoc(doc(db, 'users', user.uid))
                    // check if user needs to set a new password
                    // console.log('userDoc:', userDoc.data())
                    if (userDoc.exists() && userDoc.data().setNewPassword) {
                        setNewPasswordRequired(true)
                    } else {
                        dispatch(setUserIdScreenName({
                            userId: user.uid,
                            userScreenName: user.displayName ? user.displayName : user.email,
                            userIdToken: await user.getIdToken()
                        }))
                        navigate(from)
                    }
                }
            }
        } catch (error) {
            console.error('Error logging in:', error)
            // Handle different types of login errors
            if (error.code === 'auth/user-not-found') {
                setRegistrationError('Kein Benutzer mit dieser E-Mail gefunden.')
            } else if (error.code === 'auth/wrong-password') {
                setRegistrationError('Falsches Passwort oder ungültige E-Mail. Bitte versuchen Sie es erneut.')
            } else {
                setRegistrationError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.')
            }
        }
        setIsLoading(false)
    }

    const handleNewPasswordSubmit = async () => {
        setIsLoading(true)
        const auth = getAuth()
        setIsLoading(true)
        if (!auth.currentUser) {
            setRegistrationError('No user logged in.')
            setIsLoading(false)
            return
        }
        try {
            await updatePassword(auth.currentUser, newPassword)
            const userRef = doc(db, 'users', auth.currentUser.uid)
            await updateDoc(userRef, { setNewPassword: false })
            dispatch(setUserIdScreenName({
                userId: auth.currentUser.uid,
                userScreenName: auth.currentUser.displayName ? auth.currentUser.displayName : auth.currentUser.email,
                userIdToken: await auth.currentUser.getIdToken()
            }))
            navigate(from)
        } catch (error) {
            console.error('Error updating password:', error)
            setRegistrationError(error.message)
        }
        setIsLoading(false)
    }

    const handlePasswordReset = async () => {
      setIsLoading(true)
      if (!email) {
          setRegistrationError('Bitte geben Sie Ihre E-Mail-Adresse ein.')
          setIsLoading(false)
          return
        }
        try {
            await sendPasswordResetEmail(auth, email)
            setRegistrationError('Falls Sie ein registriertes Konto haben, wurde eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts an Ihre E-Mail-Adresse gesendet.')
        } catch (error) {
            console.error('Error sending password reset email:', error)
            setRegistrationError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.')
        }
        setIsLoading(false)
    }

    return (
        <div className={styles.signIn} style={{ marginTop: headerHeight }}>
            {isLoading && (
                <div className={styles.waitingOverlay}>
                    <CircularProgress/>
                </div>
            )}
            <h1>Willkommen!</h1>
            <div style={{ marginTop: '2rem', textAlign: 'center', width: isMobile ? '100%' : '50%' }}>
                <p>Anmelden oder ein neues Konto registrieren, um Anonymisierung zu erleben.</p>
            </div>
            <form key={formKey} style={isMobile ? { width: '100%' } : { width: '40%' }}>
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px' // Adjust this value to your preference
                        }
                    }}
                    type="text" // initially set as text
                    label="Email"
                    placeholder="Email"
                    required
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                    margin="normal"
                    value={email}
                    inputRef={emailInputRef}
                    autoCorrect='off'
                    autoComplete='off'
                    onBlur={handleEmailBlur}
                />
                <TextField
                    sx={{
                        width: isMobile ? '350px' : '450px',
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '20px' // Adjust this value to your preference
                        }
                    }}
                    type="password" // initially set as text
                    label="Passwort"
                    placeholder="Passwort"
                    required
                    onChange={handlePasswordChange}
                    error={!!passwordError}
                    helperText={passwordError}
                    margin="normal"
                    value={password}
                    inputRef={passwordInputRef}
                    autoCorrect='off'
                    autoComplete='off'
                    onBlur={handlePasswordBlur}
                />
                <Button
                    label="Anmelden"
                    slug="Anmelden"
                    disabled={!isFormValid}
                    isCTA={isFormValid}
                    onClick={handleLoginClick}
                />
                <p onClick={() => setShowRegistration(!showRegistration)} style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'underline'
                }}>
                    oder registrieren {showRegistration ? 'v' : '>'}
                </p>
                <p onClick={handlePasswordReset} style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'underline'
                }}>
                    Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihre E-Mail-Adresse ein und klicken Sie auf diesen Link.
                </p>
                <div className={`${styles.registrationSlider} ${showRegistration ? styles.open : ''}`}>
                    <TextField
                        sx={{
                            width: isMobile ? '350px' : '450px',
                            boxSizing: 'border-box',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px' // Adjust this value to your preference
                            }
                        }}
                        label="Vorname"
                        required
                        placeholder="Vorname"
                        onChange={handleFirstNameChange}
                        margin="normal"
                        value={firstName}
                    />

                    <TextField
                        sx={{
                            width: isMobile ? '350px' : '450px',
                            boxSizing: 'border-box',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px' // Adjust this value to your preference
                            }
                        }}
                        label="Name"
                        required
                        placeholder="Name"
                        onChange={handleLastNameChange}
                        margin="normal"
                        value={lastName}
                    />
                    <TextField
                        sx={{
                            width: isMobile ? '350px' : '450px',
                            boxSizing: 'border-box',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px' // Adjust this value to your preference
                            }
                        }}
                        label="Organisation"
                        required
                        placeholder="Organisation"
                        onChange={handleOrganizationChange}
                        margin="normal"
                        value={organization}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                name="celonisConsent"
                                color="primary"
                            />
                        }
                        label={<p style={{ margin: 0, textAlign: 'left', paddingTop: '1rem', paddingBottom: '1rem' }}>
                            Ich möchte gerne mehr über die Produkte und Dienstleistungen von Elephant Labs erfahren. Ich
                            stimme hierfür der elektronischen Kontaktaufnahme durch Elephant Labs zu.</p>}
                    />
                    <Button
                        label="Registrieren"
                        slug="Registrieren"
                        disabled={!isFormValid || organization.length === 0 || firstName.length === 0 || lastName.length === 0}
                        isCTA={isFormValid && organization.length > 0 && firstName.length > 0 && lastName.length > 0}
                        onClick={(event) => handleRegisterClick(event)} // Pass the event here
                    />
                </div>
            </form>

            {/* use PasswordDialog */}
            <PasswordDialog
                newPasswordRequired={newPasswordRequired}
                setNewPasswordRequired={setNewPasswordRequired}
                handleNewPasswordSubmit={handleNewPasswordSubmit}
                passwordRegex={passwordRegex}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                isLoading={isLoading}
            />

            {registrationError && (
                <p className="error-message" style={{ color: 'red', marginTop: '1rem' }}>{registrationError}</p> // Display error message
            )}
            <div style={{ marginTop: '2rem', textAlign: 'left', width: isMobile ? '100%' : '40%' }}>
                <h6>Wenn Sie dieses Formular abschicken, stimmen Sie einer Speicherung und Weiterverarbeitung Ihrer
                    persönlichen Daten durch Elephant Labs gemäß <Link to="/privacy" style={{
                        color: 'inherit',
                        textDecoration: 'underline'
                    }}>unserer Datenschutzrichtlinien</Link> zu.</h6>
            </div>
        </div>
    )
}

export default SignIn
