import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SecureFileStatusManager from '../pages/Playground/AnonymizationPWA/Logics/SecureFileStatusManager'

// // Define an async thunk outside your slice
// export const updateFileStatusAsync = createAsyncThunk(
//     'selectedFileSlice/updateFileStatus',
//     async ({ userId, fileId }) => {
//       const status = await SecureFileStatusManager.getStatus(userId, fileId)
//       console.log('status changed', status)
//       return { fileId, status } // This return value is used as the action payload in extraReducers
//     }
// )

const folderTreeDataStateSlice = createSlice({
  name: 'selectedFileSlice',
  initialState: {},
  reducers: {
    setFileData: (state, action) => {
      const fileData = action.payload
      const fileId = fileData.id

      if (!state[fileId]) {
        state[fileId] = {} // Initialize the file data object if it doesn't exist
      }

      // Update the file data object with new data, overwriting existing fields
      // This assumes that the action.payload contains all the fields you want to update
      state[fileId] = {
        ...fileData
      }
    },
    updateFileData: (state, action) => {
      const { fileId, newData } = action.payload
      // console.log('old data', state[fileId])
      // console.log('new data', newData)

      if (!state[fileId]) {
        state[fileId] = newData // If the file doesn't exist, just use the new data
      } else {
        // Preserve specific fields from the old data
        const preservedData = {
          url: state[fileId].url
        }

        // Overwrite the file data with the new data, but keep 'url' and 'mapping' from the old data
        state[fileId] = {
          ...newData,
          ...preservedData
        }
      }
    },
    removeFileData: (state, action) => {
      const fileId = action.payload
      delete state[fileId]
    },
    updateAnonymizationMapping: (state, action) => {
      const { fileId, newAnonymization } = action.payload
      if (state[fileId]) {
        state[fileId].mapping = newAnonymization
      }
    },
    updateBlobUrl: (state, action) => {
      const { fileId, blobUrl } = action.payload
      if (state[fileId]) {
        state[fileId].blobUrl = blobUrl
      }
    }
  }
  // extraReducers: (builder) => {
  //   builder.addCase(updateFileStatusAsync.fulfilled, (state, action) => {
  //     const { fileId, busySince } = action.payload
  //     if (state[fileId]) {
  //       state[fileId].busySince = busySince
  //     }
  //   })
  // }
})

export default folderTreeDataStateSlice.reducer
export const {
 setFileData,
  updateFileData,
  updateAnonymizationMapping,
  updateBlobUrl
} = folderTreeDataStateSlice.actions
