import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { refreshToken } from '../slices/authSlice'

const TokenRefreshComponent = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(refreshToken())
        }, 25 * 60 * 1000) // 30 minutes

        // Clear the interval on component unmount
        return () => clearInterval(interval)
    }, [dispatch])

    return null // This component doesn't render anything
}

export default TokenRefreshComponent
